let api = {
	// base: 'https://sx.ztl.yuetuvip.net/api', // 生产
	// base: 'https://sxqa.ztl.yuetuvip.net/api', // 测试
	base: process.env.REACT_APP_API_URL,


	commoditiesTypes: '/tour/commodities/types',
	commoditiesList: '/tour/commodities/',
	commoditiesDetail: '/tour/commodities/',
	orderList: '/tour/orders/',
	eliminateList: '/tour/orders/use/',
	eliminateOrder: '/tour/orders/status',
	orderDetails: '/tour/commodities/detail/',
	refundOrder:'/tour/refund/orders',
	//  南站疏解
	trains: '/tour/trains',
	listFile: '/tour/points',
	pointsAll: '/tour/points/all',
	points: '/tour/points/',
	usersData: '/tour/users/', //根据openid获取用户信息
}

console.log(process.env.REACT_APP_API_URL)

module.exports = api
