import React, { useState, useEffect } from 'react'
import subtract from '../image/subtract_shop.png'
import add from '../image/add_shop.png'
import close from '../image/close.png'
import right from '../image/right.png'
import api from '../utils/api'
import '../css/details.scss'
import { Drawer, ActivityIndicator, Toast, Carousel } from 'antd-mobile'
import { Link } from 'react-router-dom'
import global from '../utils/global'
import moment from 'moment'
import 'moment/locale/zh-cn'

const Details = props => {
	const [open, setOpen] = useState(false)
	const [detailsData, setDetailsData] = useState(null)
	const [loading, setLoading] = useState(false)

	const [tagList, setTagList] = useState([])
	const [jointName, setJointName] = useState('')
	const [paramsToList, setParamsToList] = useState([])
	const [count, setCount] = useState(1)
	const [tempParams, setTempParams] = useState([]) //临时变量
	const [sum, setSum] = useState([]) //总计
	const [imgHeight, setImgHeight] = useState(170) //图片高度
	const [selDay, setSelDay] = useState() //图片高度

	const skuInfo = (detailsData && detailsData.skuInfo) || ''
	const productShowModel = (detailsData && detailsData.productShowModel) || ''

	const kuInfoMapName =
		(detailsData &&
			detailsData.skuInfo &&
			detailsData.skuInfo.codeSkuInfoMap &&
			detailsData.skuInfo.codeSkuInfoMap[jointName] &&
			detailsData.skuInfo.codeSkuInfoMap[jointName]) ||
		null

	const stockCount = (kuInfoMapName && kuInfoMapName.stockCount) || ''

	const price = (kuInfoMapName && kuInfoMapName.price) || ''

	const skuId = (kuInfoMapName && kuInfoMapName.skuId) || ''

	const description =
		detailsData &&
		detailsData.productShowModel &&
		detailsData.productShowModel.description
			? htmlDecode(detailsData.productShowModel.description)
			: null

	useEffect(() => {
		const DATE_FORMAT = 'YYYY-MM-DD'
		let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA)
		let storageTripsDataStr = storageTripsData
			? JSON.parse(storageTripsData)
			: {}

		const startDate = moment(storageTripsDataStr.departDate).format(DATE_FORMAT)
		const endDate = moment(storageTripsDataStr.returnDate).format(DATE_FORMAT)

		setLoading(true)
		const code = props.location.state && props.location.state.code
		fetch(
			api.base +
				api.orderDetails +
				code +
				`?startDate=${startDate}&endDate=${endDate}`
		)
			.then(res => res.json())
			.then(res => {
				setLoading(false)

				if (res.code === 200 && res.data) {
					setDetailsData(res.data)

					const detailsDataRes = res.data
					let tagList = []
					let myList

					for (var i = 1; i < 10; i++) {
						let skuInfoItem = detailsDataRes.skuInfo['tagL' + i]
						if (skuInfoItem) {
							let bol = skuInfoItem.tagNames.some(item => {
								return item === selDay
							})

							myList = skuInfoItem.tagNames.map((item, index) => {
								return {
									name: item,
									checked: bol ? item === selDay : index === 0,
									num: 0
								}
							})
							tagList.push({ tagNames: myList, tagLabel: skuInfoItem.tagLabel })
						}
					}
					setTagList(tagList)

					const selectList = tagList.map(tagItem => {
						return tagItem.tagNames.filter(nameItem => {
							return nameItem.checked
						})
					})

					let nameRes = ''
					selectList.forEach((item, index) => {
						item.forEach(item => {
							nameRes += item.name
							if (index !== selectList.length - 1) {
								nameRes += '-'
							}
						})
					})

					setJointName(nameRes)
					let listRes = []
					listRes.push({ name: nameRes, num: 1 })
					setTempParams(listRes)
				} else {
					Toast.fail(res.msg, 1)
				}
			})
			.catch(e => {
				setLoading(false)
			})
	}, [props.location.state, selDay])

	useEffect(() => {
		let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA)
		let storageTripsDataStr = storageTripsData
			? JSON.parse(storageTripsData)
			: ''

		setSum(
			storageTripsDataStr['totalNum'] ? storageTripsDataStr['totalNum'] : 0
		)

		let storageSelectDay = sessionStorage.getItem(global.STORAGE_KEY_SELECT_DAY)
		let storageSelectDayStr = storageSelectDay
			? JSON.parse(storageSelectDay)
			: {}

		setSelDay(moment(storageSelectDayStr).format('MMMDo'))
	}, [])

	/**
	 * 返回商品规格view
	 */
	function renderStandard() {
		if (!tagList) {
			return null
		}

		return tagList.map((itemParent, indexParent) => {
			return (
				<div key={indexParent}>
					<span className="txt-use-pop">选择{itemParent.tagLabel}</span>
					<div className="ticket-box">
						{itemParent.tagNames.map((item, index) => {
							return (
								<span
									key={index}
									className={
										item.checked
											? 'ticket-item-checked'
											: 'ticket-item-unchecked'
									}
									onClick={() => handleCheck(indexParent, index)}
								>
									{item.name}
								</span>
							)
						})}
					</div>
				</div>
			)
		})
	}

	/**
	 * 点击选择规格
	 */
	function handleCheck(indexParent, indexRes) {
		const newTagNames = tagList[indexParent].tagNames.map((item, index) => {
			return { ...item, checked: index === indexRes }
		})

		const newTagList = tagList.map((item, index) => {
			if (index === indexParent) {
				return { tagNames: newTagNames, tagLabel: item.tagLabel }
			} else {
				return { tagNames: item.tagNames, tagLabel: item.tagLabel }
			}
		})

		setTagList(newTagList)

		const selectList = newTagList.map(tagItem => {
			return tagItem.tagNames.filter(nameItem => {
				return nameItem.checked
			})
		})

		let nameRes = ''
		selectList.forEach((item, index) => {
			item.forEach(item => {
				nameRes += item.name
				if (index !== selectList.length - 1) {
					nameRes += '-'
				}
			})
		})

		setJointName(nameRes)

		if (!tempParams.some(item => item.name === nameRes)) {
			tempParams.push({ name: nameRes, num: 1 })
			setTempParams(tempParams)
		}
	}

	/**
	 * 加入行程单
	 */
	function handleAddTravel() {
		if (!open) {
			setOpen(true)
		} else {
			if (skuId === '') {
				Toast.fail('请选择规格', 1)
			} else {
				let bol = paramsToList.some(item => item.skuId === skuId)

				setSum(sum + count)

				let params = {
					title: productShowModel.title,
					imgSrc: productShowModel.coverAddr,
					names:
						jointName.indexOf('-') !== -1 ? jointName.split('-') : [jointName],
					price: price,
					skuId: skuId,
					num: count
				}

				if (!bol) {
					paramsToList.push(params)
				} else {
					for (var i = 0; i < paramsToList.length; i++) {
						if (paramsToList[i].skuId === skuId) {
							paramsToList[i].num += count
						}
					}
				}
				setParamsToList(paramsToList)
				var pathParams = {
					params: paramsToList,
					categoryId: props.location.state && props.location.state.categoryId,
					date: props.location.state && props.location.state.date
				}
				sessionStorage.setItem(
					global.STORAGE_KEY_ADD_TRIPS_DATA,
					JSON.stringify(pathParams)
				)
				setCount(1)
				Toast.success('添加行程成功', 1)
			}
		}
	}

	/**
	 * 去转译符号
	 * @param {*} value
	 */
	function htmlDecode(value) {
		const str = value.replace('"', '').replace(/[\\]/g, '')
		return str.substring(0, str.length - 1)
	}

	/**
	 * 关闭弹窗
	 */
	function closeModal() {
		setOpen(false)
		let listRes = []
		listRes.push({ name: jointName, num: 1 })
		setTempParams(listRes)
	}

	/**
	 * return 弹窗view
	 */
	const sidebar = (
		<div className="drawer-container">
			<img
				src={close}
				alt=""
				className="close-img"
				onClick={() => closeModal()}
			/>
			<div className="add-padding">
				<div className="top-view">
					<div className="left">
						<img src={productShowModel.coverAddr} alt="" className="left-img" />
					</div>
					<div className="right">
						<span className="left-txt-name">{productShowModel.title}</span>
						<div className="price-box">
							<span className="left-txt-price">
								{price.length > 0 ? '¥' + price : ''}
							</span>
							<span className="right-txt">
								{stockCount.length > 0 ? '库存量' + stockCount : ''}
							</span>
						</div>
					</div>
				</div>

				{renderStandard()}

				<div className="sel-num-box">
					<span className="txt-select-num">选择数量</span>

					<div className="right">
						<img
							src={subtract}
							alt=""
							className="img-shop-operation"
							onClick={() => setCount(count - 1)}
						/>

						<span className="num">{count}</span>
						<img
							src={add}
							alt=""
							className="img-shop-operation"
							onClick={() => setCount(count + 1)}
						/>
					</div>
				</div>
			</div>

			{/* <div className="bottom-view">
				<Link className="confirm-cancel" to={getPath()}>
					查看行程单({sum})
				</Link>

				<div className="confirm-btn" onClick={() => handleAddTravel()}>
					加入行程单
				</div>
			</div> */}
		</div>
	)

	/**
	 * 返回title
	 */
	function getSelectTitle() {
		if (!tagList) {
			return
		}
		let titleRes = '选择'
		tagList.forEach((item, index) => {
			titleRes += item.tagLabel
			if (index !== tagList.length - 1) {
				titleRes += '、'
			}
		})

		return titleRes
	}

	function getPath() {
		var pathParams = {
			pathname: '/select_service'
		}

		return pathParams
	}

	/**
	 * 图片轮播组件
	 */
	function renderTopImg() {
		const imgList =
			detailsData && detailsData.picsList ? detailsData.picsList : []
		return (
			<Carousel autoplay={false} infinite={true}>
				{imgList.map(val => (
					<div
						key={val.picId}
						style={{
							display: 'inline-block',
							width: '100%',
							height: imgHeight,
							minHeight: imgHeight
						}}
					>
						<img
							src={`${val.cdndomainhttp}${val.originalcdnaddr}`}
							alt=""
							style={{ width: '100%', verticalAlign: 'top' }}
							onLoad={() => {
								// fire window resize event to change height
								window.dispatchEvent(new Event('resize'))
								setImgHeight('auto')
							}}
						/>
					</div>
				))}
			</Carousel>
		)
	}

	return (
		<Drawer
			enableDragHandle
			sidebar={sidebar}
			open={open}
			style={{ minWidth: document.documentElement.clientWidth }}
			position="bottom"
			onOpenChange={() => setOpen(!open)}
		>
			<ActivityIndicator toast text="正在加载" animating={loading} />

			<div className="details-container">
				<div>
					{renderTopImg()}
					<div className="details-top-info">
						<span className="ticket-name">{productShowModel.title}</span>
						<div className="ticket-price">
							<span className="ticket-price-txt">
								{productShowModel
									? '¥' +
									  productShowModel.minPrice +
									  ' - ' +
									  productShowModel.maxPrice
									: ''}
							</span>
							<span className="ticket-sell-txt">
								{(skuInfo &&
									skuInfo.codeSkuInfoMap['01'] &&
									skuInfo.codeSkuInfoMap['01'].stockCount) ||
									''}
							</span>
						</div>
					</div>

					<div
						className="select-use-people"
						style={{ marginBottom: description ? 0 : 75 }}
						onClick={() => setOpen(true)}
					>
						<span className="text">{getSelectTitle()}</span>
						<img src={right} alt="" className="right-icon" />
					</div>

					{description ? (
						<div
							className="inner-html"
							dangerouslySetInnerHTML={{
								__html: description
							}}
						/>
					) : null}
				</div>
			</div>

			<div className="bottom-view-parent">
				<Link className="confirm-cancel" to={getPath()}>
					查看行程单({sum})
				</Link>

				<div className="confirm-btn" onClick={() => handleAddTravel()}>
					加入行程单
				</div>
			</div>
		</Drawer>
	)
}

export default Details
