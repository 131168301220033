import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Index from "./page/Index";
import SelectRouteInfo from "./page/SelectRouteInfo.js";
import SelectService from "./page/SelectService";
import SelectTrainTicket from "./page/SelectTrainTicket";
import Details from "./page/Details";
import OrderList from "./page/OrderListPage";
import OrderDetails from "./page/OrderDetailsPage";
import OrderEliminatePage from "./page/OrderEliminatePage";
import ConfirmationPage from "./page/ConfirmationPage";
import OrderEliminateSucceedPage from "./page/OrderEliminateSucceedPage";
import Privacy from "./page/PrivacyPage";

// @description 智慧枢纽 start
import AddItinerary from "./view/AddItinerary"; // 添加行程
import Guide from "./view/Guide"; // 出站交通引导
import Text from "./view/Guide/Text/text"; // 出站交通引导
import NearbyCar from './view/NearbyCar'; // 附近的公交/地铁
import StationList from './view/StationList'; // 站点列表
import StationScreen from "./view/StationScreen/index";
import InfoInput from "./view/InfoInput/index"; // 行程录入
import ListFile from "./view/ListFile/index"; // 站厅取票 和小红帽
import FreeCar from "./view/FreeCar/index"; // 免费摆渡车


// @description 智慧枢纽 end

const App = () => (
  <BrowserRouter basename="/">
    <Route exact path="/" component={Index} />
    <Route exact path="/select_route" component={SelectRouteInfo} />
    <Route exact path="/select_service" component={SelectService} />
    <Route exact path="/select_train_ticket" component={SelectTrainTicket} />
    <Route exact path="/confirmation" component={ConfirmationPage} />
    <Route exact path="/details" component={Details} />
    <Route exact path="/order_list" component={OrderList} />
    <Route exact path="/order_details" component={OrderDetails} />
    <Route exact path="/order_eliminate" component={OrderEliminatePage} />
    <Route
      exact
      path="/order_eliminate_succeed"
      component={OrderEliminateSucceedPage}
    />
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/add_itinerary" component={AddItinerary} />
    <Route exact path="/station-screen" component={StationScreen} />
    <Route exact path="/info-input" component={InfoInput} />
    <Route exact path="/guide" component={Guide} />
    <Route exact path="/text" component={Text} />
    <Route exact path="/nearby-car" component={NearbyCar} />
    <Route exact path="/station-list" component={StationList} />
    <Route exact path="/list-file" component={ListFile} />
    <Route exact path="/free_car" component={FreeCar} />
    
  </BrowserRouter>
);

export default App;
