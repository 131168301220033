import React, { useState, useEffect, useRef } from "react";
import "../css/orderList.scss";
import global from "../utils/global";
import api from "../utils/api";
import lodash from "lodash";
import { Modal, Toast, ActivityIndicator, TextareaItem } from "antd-mobile";

var QRCode = require("qrcode.react");

function OrderListPage(props) {
  console.log("props", props);

  const [dataSource, setDataSource] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [subOrderQR, setSubOrderQR] = useState();
  const [subOrderStatus, setSubOrderStatus] = useState();
  const [loading, setLoadingState] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [refundCause, setRefundCause] = useState();

  const useRecordsRef = useRef();
  const notUseRecordsRef = useRef();

  function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  }

  let code = getQueryString("code");
  let jsonStr = JSON.stringify(code);
  sessionStorage.setItem(global.STORAGE_KEY_ORDER_CODE, jsonStr);
  console.log("code", code);

  useEffect(() => {
    setLoadingState(true);
    let codeData = sessionStorage.getItem(global.STORAGE_KEY_ORDER_CODE);
    let codeStr = codeData ? JSON.parse(codeData) : "";
    fetch(api.base + api.orderList + codeStr)
      .then(response => response.json())
      .then(res => {
        setLoadingState(false);
        if (res.code === 200) {
          if (res.data) {
            setDataSource(res.data);
            // 去除已核销、未核销最后一个顿号
            useRecordsRef.current.innerHTML = useRecordsRef.current.innerHTML.substr(0, useRecordsRef.current.innerHTML.length - 8) + '</span>';
            notUseRecordsRef.current.innerHTML = notUseRecordsRef.current.innerHTML.substr(0, notUseRecordsRef.current.innerHTML.length - 8) + '</span>';
          } else {
            Toast.info("此订单暂无数据");
          }
        } else {
          Toast.info(res.message);
        }
      });
  }, []);

  function uploadDataSource() {
    setLoadingState(true);
    let codeData = sessionStorage.getItem(global.STORAGE_KEY_ORDER_CODE);
    let codeStr = codeData ? JSON.parse(codeData) : "";
    fetch(api.base + api.orderList + codeStr)
      .then(response => response.json())
      .then(res => {
        setLoadingState(false);
        if (res.code === 200) {
          if (res.data) {
            setDataSource(res.data);
            // 去除已核销、未核销最后一个顿号
            useRecordsRef.current.innerHTML = useRecordsRef.current.innerHTML.substr(0, useRecordsRef.current.innerHTML.length - 8) + '</span>';
            notUseRecordsRef.current.innerHTML = notUseRecordsRef.current.innerHTML.substr(0, notUseRecordsRef.current.innerHTML.length - 8) + '</span>';
          } else {
            Toast.info("此订单暂无数据");
          }
        } else {
          Toast.info(res.message);
        }
      });
  }

  function refundOrder() {
    setShowRefund(true);
  }
  function onCloseRefund() {
    setShowRefund(false);
  }
  function onClose() {
    setModalVisible(false);
  }
  function changeRefundCause(value) {
    setRefundCause(value);
  }
  function affirmRefundSubmit() {
    if (refundCause && refundCause.length > 0) {
      setLoadingState(true);
      let codeData = sessionStorage.getItem(global.STORAGE_KEY_ORDER_CODE);
      let codeStr = codeData ? JSON.parse(codeData) : "";
      var params = {
        orderNo: codeStr,
        content: refundCause
      };
      var jsonheader = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8"
        }
      };
      var options = lodash.extend(jsonheader, {
        body: JSON.stringify(params)
      });
      fetch(api.base + api.refundOrder, options)
        .then(response => response.json())
        .then(res => {
          setLoadingState(false);
          console.log(res);

          if (res.code === 200) {
            Toast.info("申请成功");
            uploadDataSource();
            setShowRefund(false);
          } else {
            Toast.info(res.message || res.msg);
          }
        });
    } else {
      Toast.info("请输入退款原因！");
    }
  }

  return (
    <div className="order-list-container ">
      <ActivityIndicator toast text="正在加载" animating={loading} />

      <div>
        <div className="title-view">
          <span className="name-font">
            {dataSource ? dataSource.contactName : ""}
          </span>
          <span className="phone-number-font">
            {dataSource ? dataSource.contactPhone : ""}
          </span>
        </div>
        <div className="list-container">
          {dataSource &&
          dataSource.orderDetailsResList &&
          dataSource.orderDetailsResList.length > 0
            ? dataSource.orderDetailsResList.map((item, index) => {
                return (
                  <div className="list-item-container" key={index}>
                    <img
                      src={item ? item.picAddr : ""}
                      alt=""
                      className="item-icon"
                    />

                    <div className="list-item-title-container">
                      <div className="list-item-synopsis-container">
                        <span className="list-item-title-font">
                          {item ? item.commodityName : ""}
                        </span>
                        <span className="list-item-status-font">
                          {item ? item.status.desc : ""}
                        </span>
                      </div>
                      <div className="list-item-synopsis-container"  style={{marginTop: '10px'}}>
                        <span className="list-item-synopsis-font">
                          {item ? item.subCommodityName : ""}
                        </span>
                        <span className="list-item-synopsis-font">
                          ¥{item ? item.price : ""}
                        </span>
                        {item &&
                        !item.used &&
                        item.pushQRAddr &&
                        item.pushQRAddr.length > 0 ? (
                          <div
                            className="list-item-examine"
                            onClick={() => {
                              setModalVisible(true);
                              setSubOrderStatus(item ? item.status.desc : "");
                              setSubOrderQR(item ? item.pushQRAddr : "");
                            }}
                          >
                            查看核销券码
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                      <div ref={useRecordsRef} style={{color: '#999999', fontSize: '12px', marginTop: '8px'}}>
                      已核销：{item.useRecords ? item.useRecords.map((it, index) => {
                          // eslint-disable-next-line
                          if(it && it.used) {
                            return (
                              <span key={it.commodityName}>{it.commodityName}、</span>
                            )
                          }
                          return null
                        }) : '无'}
                      </div>
                      <div ref={notUseRecordsRef} style={{color: '#547EFF', fontSize: '12px', marginTop: '8px'}}>
                      未核销：{item.useRecords ? item.useRecords.map((it) => {
                          // eslint-disable-next-line
                          if(it && !it.used) {
                            return (
                              <span key={it.commodityName}>{it.commodityName}、</span>
                            )
                          } else {
                            return null
                          }
                        }) : '无'}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="verify-view">
          <span className="verify-view-title">核销券码：</span>
          <span className="verify-view-status">
            {dataSource ? dataSource.status.desc : ""}
          </span>
        </div>
        <div className="QRCode-view">
          <QRCode
            value={dataSource ? dataSource.pushQRAddr : ""}
            size={140}
            fgColor={dataSource && dataSource.used ? "#CFCFCF" : "#333333"}
          />
        </div>
        <div className="order-info-view">
          <div className="order-info-title-view">
            <span className="order-info-title-font">订单编号：</span>
            <span className="order-info-title-font">下单时间：</span>
            {dataSource &&
            dataSource.status &&
            dataSource.status.value === 3 ? (
              <span className="order-info-title-font">核销时间：</span>
            ) : null}
          </div>
          <div className="order-info-title-view">
            <span className="order-info-title-font">
              {dataSource ? dataSource.orderNo : ""}
            </span>
            <span className="order-info-title-font">
              {dataSource ? dataSource.createTime : ""}
            </span>
            {dataSource &&
            dataSource.status &&
            dataSource.status.value === 3 ? (
              <span className="order-info-title-font">
                {dataSource ? dataSource.updateTime : ""}
              </span>
            ) : null}
          </div>
        </div>
        {dataSource && !dataSource.refund ? (
          dataSource.status && dataSource.status.value !== 3 ? (
            <div
              className="ss-next-btn-container"
              onClick={() => {
                refundOrder();
              }}
            >
              申请退款
            </div>
          ) : null
        ) : null}
        <Modal
          visible={showRefund}
          transparent
          maskClosable={false}
          title="确认退款"
          footer={[
            {
              text: <span style={{ fontSize: 14 }}>取消</span>,
              onPress: () => {
                onCloseRefund();
              }
            },
            {
              text: <span style={{ fontSize: 14 }}>确认提交</span>,
              onPress: () => {
                affirmRefundSubmit();
              }
            }
          ]}
        >
          <div>
            <span>请输入退款原因</span>
            <TextareaItem
              style={{
                fontSize: 14,
                minHeight: 80,
                background: "#FAFAFA",
                paddingLeft: 10
              }}
              placeholder="请输入退款原因"
              data-seed="logId"
              autoHeight
              onChange={value => {
                changeRefundCause(value);
              }}
            />
          </div>
        </Modal>
        <Modal
          visible={modalVisible}
          transparent
          // closable={true}
          maskClosable={false}
          onClose={() => {
            onClose();
          }}
          footer={[
            {
              text: <span style={{ fontSize: 16 }}>关闭</span>,
              onPress: () => {
                onClose();
              }
            }
          ]}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 15
            }}
          >
            <span
              style={{
                fontSize: 13,
                color: "#5C5C5C"
              }}
            >
              核销券码：
            </span>
            <span
              style={{
                fontSize: 13,
                color: "#547EFF"
              }}
            >
              {subOrderStatus}
            </span>
          </div>
          <QRCode
            value={subOrderQR ? subOrderQR : ""}
            size={140}
            fgColor={"#333333"}
          />
        </Modal>
      </div>
    </div>
  );
}

export default OrderListPage;
