import api from "./api";
import { subStrUrl } from "./util";

/**
 * @param  {string}  url         请求地址
 * @param  {string}  method      请求类型: GET/POST/PUT/DELETE
 * @param  {string}  bodyType    body提交类型: form/query/file/json
 * @param  {string}  contentType 请求头Content-Type
 * @param  {object}  body        body类型参数
 * @param  {Boolean} isApi       接口是否加上 /api, 默认为true
 * @return {object}              请求结果: json
 */

const fetchHttp = (url, options = {}) => {
  const method = options.method || "GET";
  const body = options.body || {};
  const bodyType = options.bodyType || "";
  const returnType = options.returnType || "json";
  const isApi =
    options.isApi === "" || options.isApi === undefined ? true : options.isApi;
  const params = {
    url,
    method,
    bodyType,
    returnType,
    body,
    isApi
  };
  return request(params);
};

const request = params => {
  const { bodyType, method, body } = { ...params };
  let contentType = null;
  if (method !== "GET") {
    if (Object.keys(body).length > 0) {
      if (bodyType === "form") {
        contentType = "application/x-www-form-urlencoded;charset=UTF-8";
      } else if (bodyType === "json") {
        contentType = "application/json;charset=UTF-8";
      }
    }
  }
  params.contentType = contentType;
  return http(params);
};

const http = params => {
  const { url, method, bodyType, contentType, body, isApi } = { ...params };
  let headersObj = {
    method: method,
    bodyType: bodyType
  };
  if (Object.keys(body).length > 0) {
    headersObj.body = JSON.stringify(body);
  }
  if (contentType) {
    headersObj.headers = {
      "Content-Type": contentType
    };
  }

  // 基础url，有些接口不带/api
  let baseUrl = "";
  if (isApi) {
    baseUrl = api.base;
  } else {
    baseUrl = subStrUrl(api.base);
  }

  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, headersObj)
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};

export default fetchHttp;
