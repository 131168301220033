import React, { useEffect } from "react";
import succeed from "../image/eliminate_succeed.png";

function OrderEliminateSucceedPage() {
  useEffect(()=>{
    window.addEventListener('popstate',event=>{
      console.log('popstate',event);
      
    })
  })
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems:'center',
        width:'100vw',
        height:'100vh'
      }}
    >
      <img src={succeed} alt="" style={{ width: 65, height: 65 }} />
      <span
        style={{
          fontSize: 20,
          fontFamily: "PingFang-SC-Medium",
          color: "#4A4A4A",
          marginTop:20
        }}
      >
        核销成功
      </span>
    </div>
  );
}

export default OrderEliminateSucceedPage;
