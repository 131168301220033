/**
 * 添加行程
 */
import React, { useEffect } from "react";
import { Button } from "antd-mobile";
import { urlParse, resetTitle, subStrUrl} from "../../utils/util";
import { getUsersInfo } from '../../services/services';
import api from "../../utils/api";
import store from '../../store';
import "./AddItinerary.scss";

function AddItinerary(props) {
  const urlObj = urlParse(props.location.search);
  const openid = urlObj.openID;

  useEffect(() => {
    resetTitle();
  }, [])

  const commonList = [
    {
      imgUrl: "stationScreen.png",
      text: "车站大屏",
      type: 1
    },
    {
      imgUrl: "ticket.png",
      text: "取票点",
      type: 4
    },
    {
      imgUrl: "redHat.png",
      text: "小红帽",
      type: 5
    }
  ];
  const pathName = (type) => {
    if (type) {
      if (type === 1) {
        props.history.push({ pathname: "/station-screen" });
      } else {
        props.history.push({ pathname: "/list-file", state: { type: type } });
      }
    }
  }

  // 预约出行-校验用户是否登录
  const checkLogin = () => {
    // window.location.href = `https://sxqa.ztl.yuetuvip.net/car/#/?type=trip&username=18500041413&openId=${openid}`
    // 微信环境有phone和name两个参数
    // 非微信环境下有openid参数
    // phone&&name&&!openid和!phone&&!name&&openid两个条件为true,跳到专车
    if(urlObj.phone && urlObj.name && !openid) {
      return window.location.href = `${subStrUrl(api.base)}/car/#/?type=trip&username=${urlObj.phone}`;
    }

    if(!urlObj.phone && !urlObj.name && openid) {
      // 登录状态
      return getUsersInfo(openid).then(res => {
        if (res.code === 200 && res.data) {
          // 跳转到商旅邦专车
          window.location.href = `https://sxqa.ztl.yuetuvip.net/car/#/?type=trip&username=${res.data.phone}&openID=${openid}`;
        }
      })
    }

    return window.location.href = "https://store.g2work.com/yuetucompany/joinAndRegisterCompany.action?companyID=892485852779041661148774&callBackUrl=https://sxqa.ztl.yuetuvip.net/add_itinerary";
  }

  return (
    <div className="addItinerary">
      <div className="content-wrapper">
        <div className="content">
          <div className="center">
            <div className="banner">
              <img src={require("../img/addBannar.png")} alt="" />
            </div>
            <p className="tip">请添加您的行程信息</p>
            <Button className="btn" type="primary" size="small" inline>
              添加行程
            </Button>
          </div>
          <div className="container">
            <div className="navList">
              <div
                className="nav-item"
                onClick={() => {
                  checkLogin();
                }}
              >
                <div className="img">
                  <img src={require("../img/trip.png")} alt="" />
                </div>
                <div className="text">
                  <span>预约出行</span>
                  <span>提前查询 快速出行</span>
                </div>
              </div>
              <div
                className="nav-item"
                onClick={() => {
                  const action ={
                    type:'guide',
                    guideMode: false
                  }
                  store.dispatch(action)
                  props.history.push({
                    pathname: `/guide`
                  });
                }}
              >
                <div className="img">
                  <img src={require("../img/departure.png")} alt="" />
                </div>
                <div className="text">
                  <span>快速出站</span>
                  <span>实景导航 便宜出站</span>
                </div>
              </div>
            </div>
            <span className="commonTitle">常用功能</span>
            <div className="commonList">
              {commonList.map((item, index) => {
                return (
                  <div
                    className="common-item"
                    key={index}
                    onClick={() => pathName(item.type)}
                  >
                    <div className="common-wrapper">
                      <img src={require(`../img/${item.imgUrl}`)} alt="" />
                      <span>{item.text}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItinerary;
