let global = {
    STORAGE_KEY_PAGE_TYPE: 'STORAGE_KEY_PAGE_TYPE',
    STORAGE_KEY_TRIPS_DATA: 'STORAGE_KEY_TRIPS_DATA',
	STORAGE_KEY_SELECT_DAY: 'STORAGE_KEY_SELECT_DAY',
	STORAGE_KEY_ADD_TRIPS_DATA: 'STORAGE_KEY_ADD_TRIPS_DATA',
	STORAGE_KEY_COMMODITIES_LIST: 'STORAGE_KEY_COMMODITIES_LIST',
	STORAGE_KEY_COMMODITIES_ID: 'STORAGE_KEY_COMMODITIES_ID',
	STORAGE_KEY_ORDER_CODE: 'STORAGE_KEY_ORDER_CODE',
	ELIMINATE_SUCCEED_ORDER_DATA: 'ELIMINATE_SUCCEED_ORDER_DATA'
}

module.exports = global
