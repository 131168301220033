import fetchHttp from "../utils/request";

// 获取网约车附近车辆
export const getNearbydrivers = params => {
  return fetchHttp(
    `/car/api/fe/mobile/loc/nearbydrivers?lat=${params.lat}&lng=${params.lng}`,
    {
      method: "GET",
      isApi: false
    }
  );
};

// 获取全部数据
export const getPointsAll = () => {
  return fetchHttp(`/tour/points/all`, {
    method: "GET"
  });
};

// 更新信息录入
export const updatePointsMsg = params => {
  return fetchHttp(`/tour/points/${params.code}`, {
    method: "PUT",
    bodyType: "json",
    body: params.params
  });
};

// 小红帽、站内取票
export const getOtherServices = params => {
  return fetchHttp(`/tour/points?types=${params}`, {
    method: "GET"
  });
};

// 获取公交/地铁站点车次列表
export const getPointsRoutes = params => {
  return fetchHttp(`/tour/points/${params}/routes`, {
    method: "GET"
  });
};

// 获取车次各个站
export const getPointsRoutesStation = params => {
  return fetchHttp(
    `/tour/points/${params.code}/route-stations?name=${params.name}`,
    {
      method: "GET"
    }
  );
};

// 车站大屏
export const getStationScreen = params => {
  return fetchHttp(`/tour/trains/?stationCode=${params}`, {
    method: "GET"
  });
};

// 根据openid获取用户信息
export const getUsersInfo = params => {
  return fetchHttp(`/tour/users/${params}`, {
    method: "GET"
  });
};
