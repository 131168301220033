/**
 * 地图模式
 */
import React, { useEffect, useState, memo } from "react";
import { ActivityIndicator } from "antd-mobile";
import { getNearbydrivers, getPointsAll } from '../../../services/services';
import { timeFormat } from '../../../utils/util';
import './map.scss';

const AMap = window.AMap;
// 南站经纬度
const latLngObj = {
  lat: 39.864918,
  lng: 116.37906
}

const Map = memo(function Map(props) {
  const getAllData = props.getAllData;
  let [loading, setLoading] = useState(false);
  let [allData, setAllData] = useState({})
  let [nearbyCarObj, setNearbyCarObj] = useState({})

  useEffect(() => {
    nearbyCar(latLngObj);
  }, [])

  useEffect(() => {
    if(Object.keys(allData).length > 0) {
      allData.nearbyCar = nearbyCarObj
      getAllData(allData)
    }
    // eslint-disable-next-line
  }, [allData, nearbyCarObj])

  // 获取附近车辆
  const nearbyCar = (latLngObj) => {
    getNearbydrivers(latLngObj)
      .then(res => {
        if(res.code === 200 && (res.data instanceof Array === true)) {
          let obj = {}
          const len = res.data.length;
          obj = {
            num: len,
            time: len === 0 ? 10 : 2
          }
          setNearbyCarObj({...obj})
        }
      })
  }

  useEffect(() => {
    setLoading(true)
    var map = new AMap.Map('mapDiv', {
      resizeEnable: false,
      center: [116.37906, 39.864918],
      zoom: 17,
      showIndoorMap: true,
      showFloor: (0),
      isCustom: true,
      autoMove: true,
    });
    map.on('indoor_create', function() {
      map.indoorMap.showIndoorMap('B000A83AJN',-1);
    })
    getPointsAll().then(res => {
      setLoading(false)
      if (res.code === 200 && res.data) {
        setAllData(res.data)
        let busesArr = []; // 公交
        let ferryCarsArr = []; // 免费摆渡车
        let subwaysArr = []; // 地铁
        let taxisArr = []; // 出租车
        let parkingsArr = []; // 停车场
        // 免费摆渡车
        res.data.ferryCars.forEach(item => {
          let obj = {}
          obj.name = item.type.desc;
          obj.type = item.type.value || 9;
          obj.code = item.code;
          obj.pointName = item.pointName;
          obj.address = item.address;
          if (item.waitLongTime < 30) {
            obj.icon = require('../../img/mapBusLess.png');
            obj.color = '#417505';
          } else if( item.waitLongTime >= 30 && item.waitLongTime <= 100) {
            obj.icon = require('../../img/mapBusRight.png');
            obj.color = '#F5A623';
          } else if( item.waitLongTime > 100) {
            obj.icon = require('../../img/mapBusMore.png');
            obj.color = '#D0021B';
          }
          obj.position = [item.longitude, item.latitude];
          obj.queueNum = item.queueNum;
          obj.waitLongTime = item.waitLongTime;
          obj.firstBusTime = item.firstBusTime;
          obj.lastBusTime = item.lastBusTime;
          obj.updateTime = item.updateTime;
          ferryCarsArr.push(obj);
        })
        // 公交
        res.data.buses.forEach(item => {
          let obj = {}
          obj.name = item.type.desc;
          obj.type = item.type.value || 1;
          obj.code = item.code;
          obj.pointName = item.pointName;
          obj.address = item.address;
          if (item.waitLongTime < 30) {
            obj.icon = require('../../img/mapBusLess.png');
            obj.color = '#417505';
          } else if( item.waitLongTime >= 30 && item.waitLongTime <= 100) {
            obj.icon = require('../../img/mapBusRight.png');
            obj.color = '#F5A623';
          } else if( item.waitLongTime > 100) {
            obj.icon = require('../../img/mapBusMore.png');
            obj.color = '#D0021B';
          }
          obj.position = [item.longitude, item.latitude];
          obj.queueNum = item.queueNum;
          obj.waitLongTime = item.waitLongTime;
          obj.firstBusTime = item.firstBusTime;
          obj.lastBusTime = item.lastBusTime;
          obj.updateTime = item.updateTime;
          busesArr.push(obj);
        })
        // 地铁
        res.data.subways.forEach(item => {
          let obj = {}
          obj.name = item.type.desc;
          obj.type = item.type.value || 2;
          obj.pointName = item.pointName;
          obj.address = item.address;
          obj.code = item.code;
          if (item.waitLongTime < 50) {
            obj.icon = require('../../img/mapSubwayLess.png');
            obj.color = '#417505';
          } else if (item.waitLongTime >= 50 && item.waitLongTime <= 200) {
            obj.icon = require('../../img/mapSubwayRight.png');
            obj.color = '#F5A623';
          } else if (item.waitLongTime > 200) {
            obj.icon = require('../../img/mapSubwayMore.png');
            obj.color = '#D0021B';
          }
          obj.position = [item.longitude, item.latitude];
          obj.queueNum = item.queueNum;
          obj.waitLongTime = item.waitLongTime;
          obj.firstBusTime = item.firstBusTime;
          obj.lastBusTime = item.lastBusTime;
          obj.updateTime = item.updateTime;
          subwaysArr.push(obj);
        })
        // 出租车
        res.data.taxis.forEach(item => {
          let obj = {}
          obj.name = item.type.desc;
          obj.type = item.type.value || 3;
          obj.pointName = item.pointName;
          obj.address = item.address;
          if (item.waitLongTime < 30) {
            obj.icon = require('../../img/mapTaxiLess.png');
            obj.color = '#417505';
          } else if (item.waitLongTime >= 30 && item.waitLongTime <= 80) {
            obj.icon = require('../../img/mapTaxiRight.png');
            obj.color = '#F5A623';
          } else if (item.waitLongTime > 80) {
            obj.icon = require('../../img/mapTaxiMore.png');
            obj.color = '#D0021B';
          }
          obj.position = [item.longitude, item.latitude];
          obj.queueNum = item.queueNum;
          obj.waitLongTime = item.waitLongTime;
          obj.updateTime = item.updateTime;
          taxisArr.push(obj);
        })
        // 停车场
        res.data.parkings.forEach(item => {
          let obj = {
            type: item.type.value || 6,
            name: item.type.desc,
            pointName: item.pointName,
            address: item.address,
            color: '#D0021B',
            icon: require('../../img/mapParking.png'),
            position: [item.longitude, item.latitude],
          }
          parkingsArr.push(obj);
        })
  
        var lnglats = [...ferryCarsArr, ...busesArr, ...subwaysArr, ...taxisArr, ...parkingsArr];
        var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
        // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
        lnglats.forEach( (mark) => {
          var infoWindowContent =`<div className="modal">
                                    <div style="width: 150px;margin-bottom: 5px">
                                      <span style="color: ${mark.color}">${mark.name}</span>
                                      ${mark.type === 3 || mark.type === 6 ? '' 
                                        : `<a style="color: ${'#2A73FE'}" href="/nearby-car?type=${mark.type}&code=${mark.code}">查看${mark.name}列表</a>`
                                      }
                                      
                                    </div>
                                    <a style="color: ${'#2A73FE'}" href="https://uri.amap.com/navigation?我的位置&to=${mark.position[0]},${mark.position[1]},${mark.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0">步行导航至此</a>
                                    ${mapDescFun(mark.type, mark.queueNum, mark.updateTime, mark.waitLongTime, mark.firstBusTime, mark.lastBusTime)}
                                  </div>`;
          var marker = new AMap.Marker({
            map: map,
            // icon: mark.icon,
            position: [mark.position[0], mark.position[1]],
            offset: new AMap.Pixel(-13, -30),
            content: `<div class='map-content'><img src=${mark.icon} alt=''/><span style="color: ${mark.color}">${mark.name}</span><div>`
          });
          marker.content = infoWindowContent;
          marker.on('click', function markerClick(e) {
              infoWindow.setContent(e.target.content);
              infoWindow.open(map, e.target.getPosition());
          });
          // marker.emit('click', {target: marker});
          map.setFitView();
        });
      }
    })
  // eslint-disable-next-line
  }, [latLngObj]);
  
  const timeDi = 900000; // 15分钟
  const mapDescFun = (type, queueNum, updateTime, waitLongTime, firstBusTime, lastBusTime) => {
    if(type === 6) return '';
    if(type === 3) return `<div style="width: 150px;margin-top: 5px">${`排队${queueNum ? queueNum : 0}人，预计等候${waitLongTime ? waitLongTime : 0}分钟`}</div>`;
    if(!queueNum) return `<div style="width: 150px;margin-top: 5px">首班车：${firstBusTime}，末班车：${lastBusTime}</div>`;
    if(!updateTime) return `<div style="width: 150px;margin-top: 5px">首班车：${firstBusTime}，末班车：${lastBusTime}</div>`;
    if((new Date().getTime() - new Date(timeFormat(updateTime))) > timeDi) return `<div style="width: 150px;margin-top: 5px">首班车：${firstBusTime}，末班车：${lastBusTime}</div>`;
    return `<div style="width: 150px;margin-top: 5px">排队${queueNum}人，预计等候${waitLongTime}分钟</div>`;
  }

  return (
    <div className="map">
      <ActivityIndicator toast text="正在加载" animating={loading} />
      <div id="mapDiv" className="map"></div>
    </div>
  )
})

export default Map;
