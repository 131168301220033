import React, { useState } from 'react'
import { DatePicker, WingBlank, Toast } from 'antd-mobile'
import addIcon from '../image/add_shop.png'
import subtractIcon from '../image/subtract_shop.png'
import right from '../image/right.png'
import '../css/selectRouteInfo.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import global from '../utils/global'

const DATE_FORMAT = 'MM月DD日'

const DEFAULT_DATE = new Date(new Date().setDate(new Date().getDate() + 1))
const MAX_DATE = new Date(new Date().setDate(new Date().getDate() + 32))
function SelectRouteInfo(props) {
	const [departDate, setDepartDate] = useState(DEFAULT_DATE)
	const [returnDate, setReturnDate] = useState(DEFAULT_DATE)
	const [adultNum, setAdultNum] = useState(1)
	const [childNum, setChildNum] = useState(0)
	const [oldNum, setOldNum] = useState(0)

	var path = {
		pathname: '/select_service',
		query: { departDate, returnDate, adultNum, childNum }
	}

	function getDay(num) {
		if (num === 0) {
			return '周日'
		}
		if (num === 1) {
			return '周一'
		}
		if (num === 2) {
			return '周二'
		}
		if (num === 3) {
			return '周三'
		}
		if (num === 4) {
			return '周四'
		}
		if (num === 5) {
			return '周五'
		}
		if (num === 6) {
			return '周六'
		}
	}

	function setStartDate(startDate) {
		setDepartDate(startDate)
	}

	function setEndDate(endDate) {
		setReturnDate(endDate)
	}

	function nextHandle() {
		if (adultNum + oldNum === 0) {
			Toast.info('请选择出行人数')
			return
		}
		if (returnDate.getTime() >= departDate.getTime()) {
			props.history.push(path)
			sessionStorage.setItem(global.STORAGE_KEY_PAGE_TYPE, '1')// 时间选择页
			sessionStorage.setItem(global.STORAGE_KEY_TRIPS_DATA, '')
            sessionStorage.setItem(global.STORAGE_KEY_ADD_TRIPS_DATA, '')
		} else {
			Toast.info('返程日期不能早于出发日期')
		}
	}

	const departDay = getDay(parseInt(moment(departDate).format('d')))
	const returnDay = getDay(parseInt(moment(returnDate).format('d')))

	return (
		<div className="flex-container">
			<WingBlank className="container-main">
				<DatePicker
					mode="date"
					className="date-picker"
					title="选择出发日期"
					minDate={DEFAULT_DATE}
					maxDate={MAX_DATE}
					extra="请选择日期"
					value={departDate}
					onChange={date => setStartDate(date)}
				>
					<div className="date-picker-item">
						<div className="date-picker-item-left">
							<span className="depart-date-text">出发日期</span>
							<div>
								<span className="date-text-left">
									{moment(departDate).format(DATE_FORMAT)}
								</span>
								<span className="date-text-right">{departDay}</span>
							</div>
						</div>
						<img src={right} alt="" className="right-icon" />
					</div>
				</DatePicker>
				<span className="line" />

				<DatePicker
					mode="date"
					className="date-picker"
					title="选择返程日期"
					extra="请选择日期"
					minDate={DEFAULT_DATE}
					maxDate={MAX_DATE}
					value={returnDate}
					onChange={date => setEndDate(date)}
				>
					<div className="date-picker-item">
						<div className="date-picker-item-left">
							<span className="depart-date-text">返程日期</span>
							<div>
								<span className="date-text-left">
									{moment(returnDate).format(DATE_FORMAT)}
								</span>
								<span className="date-text-right">{returnDay}</span>
							</div>
						</div>
						<img src={right} alt="" className="right-icon" />
					</div>
				</DatePicker>
				<span className="line" />

				<div className="person-item">
					<div className="person-item-text">成人</div>
					<div className="person-item-right">
						<img
							src={subtractIcon}
							alt=""
							className="person-item-img"
							onClick={() => (adultNum > 0 ? setAdultNum(adultNum - 1) : null)}
						/>
						<span className="person-item-num">{adultNum}</span>

						<img
							src={addIcon}
							alt=""
							className="person-item-img"
							onClick={() => setAdultNum(adultNum + 1)}
						/>
					</div>
				</div>
				<div className="person-item">
					<div className="person-item-text">儿童</div>
					<div className="person-item-right">
						<img
							src={subtractIcon}
							alt=""
							className="person-item-img"
							onClick={() => (childNum > 0 ? setChildNum(childNum - 1) : null)}
						/>
						<span className="person-item-num">{childNum}</span>

						<img
							src={addIcon}
							alt=""
							className="person-item-img"
							onClick={() => {
								setChildNum(childNum + 1)
							}}
						/>
					</div>
				</div>

				<div className="person-item">
					<div className="person-item-text">老人</div>
					<div className="person-item-right">
						<img
							src={subtractIcon}
							alt=""
							className="person-item-img"
							onClick={() => (oldNum > 0 ? setOldNum(oldNum - 1) : null)}
						/>
						<span className="person-item-num">{oldNum}</span>

						<img
							src={addIcon}
							alt=""
							className="person-item-img"
							onClick={() => setOldNum(oldNum + 1)}
						/>
					</div>
				</div>

				<span className="line" />

				<div
					to={path}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 50,
						backgroundColor: '#547EFF',
						fontSize: 17,
						color: '#FFFFFF',
						marginTop: 30,
						borderRadius: 4
					}}
					onClick={() => nextHandle()}
				>
					下一步
				</div>
			</WingBlank>
		</div>
	)
}

export default SelectRouteInfo
