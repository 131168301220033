import React from "react";
import { Link } from "react-router-dom";
import "../css/SelectTrainTicket.scss";

const ListItem = props => {
  return (
    <Link
      className="item"
      to={props.path}
    >
      <img src={props.data.coverAddr} alt="" className="item-icon" />
      <div className="item-content">
        <span className="item-content-title">{props.data.title}</span>
        <div>
          <span className="item-content-symbol ">¥</span>
          <span className="item-content-price">{props.data.minPrice}</span>
          {props.data.maxPrice ? (
            <span className="item-content-price">- </span>
          ) : null}
          {/* {props.data.maxPrice ? (
            <span className="item-content-symbol ">¥</span>
          ) : null} */}
          {props.data.maxPrice ? (
            <span className="item-content-price">{props.data.maxPrice}</span>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default ListItem;
