/**
 * 出站交通引导
 */
import React, { useState, useEffect, useRef } from "react";
import { Icon } from "antd-mobile";
import { resetTitle, urlParse, subStrUrl } from "../../utils/util";
import api from '../../utils/api'
import Map from "./Map/map";
import "./Guide.scss";

const AMap = window.AMap;
const taxiName = "出租车";
const netCarName = "网约车";
const busSubwayName = "公交/地铁";

function Guide(props) {
  const urlObj = urlParse(props.location.search);
  const openid = urlObj.openID;

  const [listVisible, setListVisible] = useState(false); // 是否显示模糊提示内容
  const [addressList, setAddressList] = useState([]); // 模糊提示内容
  const [selectAdd, setSelectAdd] = useState({}); // 选择地址提示的内容
  const [btnColor, setBtnColor] = useState(false); // 前往按钮颜色
  const [isSelected, setIsSelected] = useState(false); // 检测是否从列表中选择地址
  const [allData, setAllData] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const inputRef = useRef();
  const divWdithRef = useRef();

  const commonList = [
    {
      imgUrl: "screen",
      name: "车站大屏",
      type: 1
    },
    {
      imgUrl: "freeCar",
      name: "免费摆渡车",
      type: 4
    },
    {
      imgUrl: "publicCar",
      name: "共享汽车",
      type: 5
    }
  ];

  useEffect(() => {
    // 输入提示插件
    document.getElementById("mapinput").focus();

    AMap.plugin(
      ["AMap.Autocomplete", "AMap.PlaceSearch", "AMap.Geocoder"],
      function() {
        const autoOptions = {
          city: "北京", // 城市，默认全国
          input: "mapinput"
        };
        const autocomplete = new AMap.Autocomplete(autoOptions);
        AMap.event.addListener(autocomplete, "complete", function(e) {
          let arr = [];
          if (e.info === "OK") {
            e.tips.forEach(item => {
              if (item.name && item.district && item.address && item.location) {
                item.district = item.district + item.address;
                let obj = {
                  name: item.name,
                  district: item.district,
                  lat: item.location.lat,
                  lng: item.location.lng
                };
                arr.push(obj);
              }
            });
          }
          setAddressList(arr);
        });
      }
    );
  }, []);

  useEffect(() => {
    inputRef.current.blur();
    resetTitle();

    const localIsShow = sessionStorage.getItem("localIsShow") || "";
    if (localIsShow === "true") {
      divWdithRef.current.style.width = 90 + "%";
      setIsShow(true);
    }
  }, []);

  // 从地图组件中获取数据并重组数据
  const getAllData = value => {
    let arr = [
      {
        name: taxiName,
        time: "",
        desc: "",
        url: "taxi.png",
        type: "taxi",
        className: "",
        timeNum: 0,
        showRecommend: true,
      },
      {
        name: netCarName,
        time: "",
        desc: "",
        url: "netCar.png",
        type: "netCar",
        num: 0,
        className: "",
        timeNum: 0,
        showRecommend: true,
      },
      {
        name: busSubwayName,
        time: "地铁14号线23:40停运",
        desc: "运营线路：地铁14号线，公交133、458等",
        url: "bus.png",
        type: "busSubway",
        className: "",
        showRecommend: false,
      }
    ];
    for (let item in value) {
      if (item === "taxis") {
        const time = value[item].length > 0 ? value[item][0].waitLongTime : 0;
        let desc = "";
        let descArr = [];
        value[item] &&
          value[item].forEach(taxi => {
            descArr.push(
              `${taxi.pointName}${taxi.queueNum ? taxi.queueNum : 0}人`
            );
          });
        desc = descArr.join(",");
        arr[0].timeNum = time;
        arr[0].time = `预计等候${time}分钟`;
        arr[0].desc = `排队人数：${desc}`;
        arr[0].className =
          time < 30
            ? "spanGreen"
            : time >= 30 && time <= 80
            ? "spanYellow"
            : "spanRed";
      } else if (item === "nearbyCar") {
        arr[1].timeNum = value[item].time || 10;
        arr[1].time = `预计等候${value[item].time || 10}分钟`;
        arr[1].desc = `附近车辆：共${value[item].num || 0}辆`;
        arr[1].num = value[item].num || 0;
        arr[1].className = "spanGreen";
      }
    }
    // 等待时间最短显示推荐标识
    let minTime = 0;
    arr.forEach(item => {
      if(item.timeNum && item.timeNum > minTime) return minTime = item.timeNum;
    })
    arr.forEach(item => {
      if(item.timeNum && item.timeNum === minTime) return item.showRecommend = false;
    })

    setAllData(arr);
  };

  // 前往
  const goToPurpose = () => {
    if (!inputRef.current.value) {
      setBtnColor(false);
      setIsSelected(false);
      return false;
    }
    if ((btnColor && isSelected) || isSelected) {
      window.location.href = `https://uri.amap.com/navigation?我的位置&to=${selectAdd.lng},${selectAdd.lat},${selectAdd.name},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`;
    }
  };

  // 选择地址列表
  const choselist = item => {
    inputRef.current.value = item.name;
    setSelectAdd(item);
    setListVisible(false);
    setIsSelected(true);
  };

  // 检测input输入
  const onInput = () => {
    setListVisible(true);
  };

  // 检测输入内容，改变button的颜色
  const onChange = () => {
    if (!inputRef.current.value) {
      setBtnColor(false);
      setIsSelected(false);
      return false;
    }
    setBtnColor(true);
  };
  const onBlur = () => {
    window.scroll(0, 0);
    inputRef.current.blur();
  };

  const ele = document.documentElement || document.body;
  const originHeight = ele.clientHeight;
  window.addEventListener(
    "resize",
    () => {
      const resizeHeight = ele.clientHeight;
      if (resizeHeight < originHeight) {
        console.log("键盘弹起");
      } else {
        console.log("键盘收起");
        inputRef.current.blur();
      }
    },
    false
  );

  const changeWidth = () => {
    divWdithRef.current.style.width = 90 + "%";
    setIsShow(true);
    sessionStorage.setItem("localIsShow", "true");
  };
  const takeBackWidth = () => {
    setIsShow(false);
    divWdithRef.current.style.width = 15 + "%";
    sessionStorage.setItem("localIsShow", "false");
  };

  // 预约出行-校验用户是否登录
  const checkLogin = () => {
    const opemUrl = subStrUrl(api.base);
    if (urlObj.phone && urlObj.name && !openid) {
      return (window.location.href = `${opemUrl}/car/#/?type=trip&username=${urlObj.phone}`);
    }
    if (!urlObj.phone && !urlObj.name && openid) {
      return (window.location.href = `${opemUrl}/car/#/?type=trip&openID=${openid}`);
    }
    return (window.location.href = `${opemUrl}/car/#/?type=trip`);
  };

  return (
    <div className="guide-wrapper">
      <div className="header-wrapper">
        <div className="currentNum">
          <div className="input-wrapper">
            <input
              id="mapinput"
              ref={inputRef}
              unselectable="on"
              type="text"
              placeholder="请输入目的地"
              onInput={onInput}
              onBlur={onBlur}
              onChange={onChange}
            />
            <button
              onClick={() => {
                goToPurpose();
              }}
              className={
                (btnColor && isSelected) || isSelected
                  ? "go-to active"
                  : "go-to"
              }
            >
              导航
            </button>
          </div>
          <div
            id="complete"
            style={listVisible ? { display: "block" } : { display: "none" }}
          >
            {addressList
              ? addressList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="addlist"
                      onClick={() => {
                        choselist(item);
                      }}
                    >
                      <div className="add">{item.name}</div>
                      <div className="depadd">{item.district}</div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <Map getAllData={getAllData} />
      {/* 下面浮层 */}
      <div className="content-wrapper">
        <div
          className="other-tab"
          ref={divWdithRef}
          onClick={() => {
            if (!isShow) {
              changeWidth();
            }
          }}
        >
          {isShow ? (
            <ul className="other-list">
              {commonList.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      if (item.type === 1) {
                        props.history.push({ pathname: "/station-screen" });
                      } else if (item.type === 4) {
                        props.history.push({
                          pathname: "/free_car"
                        });
                      } else if (item.type === 5) {
                        window.location.href =
                          "https://go.shouqiev.com/?tagName=共享汽车#/";
                      }
                    }}
                  >
                    <span>
                      <img alt="" src={require(`../img/${item.imgUrl}.png`)} />
                    </span>
                    <span>{item.name}</span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div>更多</div>
          )}
          <Icon
            className="icon"
            type={isShow ? "left" : "right"}
            color="#999"
            onClick={() => {
              if (isShow) {
                takeBackWidth();
              }
            }}
          />
        </div>
        <div className="car-list">
          <div
            className="placeholder-div"
            style={!allData.length ? { display: "block" } : { display: "none" }}
          >
            正在加载...
          </div>
          <ul>
            {allData &&
              allData.map((item, index) => {
                return (
                  <li
                    className="list-item"
                    key={index}
                    onClick={() => {
                      if (item.type === "netCar") {
                        checkLogin();
                      } else {
                        props.history.push({
                          pathname: "/text",
                          state: { type: item.type }
                        });
                      }
                    }}
                  >
                    <div className="item-icon">
                      <img src={require(`../img/${item.url}`)} alt="" />
                    </div>
                    <div className="item-text">
                      <h3>
                        {item.name}
                        <span className={item.className}>{item.time}</span>
                      </h3>
                      <p>{item.desc}</p>
                      {item.showRecommend ? (
                        <div className="recommend">推荐</div>
                      ) : null}
                    </div>
                    <div className="item-rigth-icon">
                      <Icon className="icon" type="right" color="#999" />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Guide;
