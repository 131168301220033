/**
 * 判断是否为空
 */
export const isEmpty = obj => {
  if (obj === undefined || obj === null || obj === "") {
    return true;
  }

  if (typeof Array.isArray === "function") {
    return Array.isArray(obj) ? obj.length === 0 : true;
  } else {
    if (Object.prototype.toString.call(obj) === "[object Array]") {
      return obj.length === 0;
    }
  }

  return false;
};

/**
 * 解析url参数
 * @example  ?id=12345&a=b
 * @return Object {id:12345,a:b}
 * */
export function urlParse(url) {
  let obj = {};
  let reg = /[?&][^?&]+=[^?&]+/g;
  let arr = url.match(reg);
  if (arr) {
    arr.forEach(item => {
      let tempArr = item.substring(1).split("=");
      let key = decodeURIComponent(tempArr[0]);
      let val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
}

/**
 * 检测是否是微信浏览器
 */
export const __isWeChat = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  var REG = /MicroMessenger/i;
  if (REG.test(ua)) return true;
  else return false;
};

/**
 * 重写title
 */
export const resetTitle = () => {
  document.title = '南站疏解'
}

/**
 * 检测公交线路名称
 */

 export const checkText = (value, text) => {
  if(!value || !text) return false;
  if(value.substr(-1) === text) return true;
 }

/**
 * 时间横线转成斜线
 */
export const timeFormat = (time) => {
  if(!time) return false;
  const n = time.replace(new RegExp('-', 'gm'), '/');
  return new Date(n).getTime();
}

/**
 * 检测是否是微信浏览器
 */
export const isWeChat = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  var REG = /MicroMessenger/i;
  if (REG.test(ua)) return true;
  else return false;
};

/**
 * 截取接口的 /api
 */

 export const subStrUrl = (URL) => {
   console.log(URL)
  let resUrl = ''

  if(URL.indexOf('/api') > -1) {
    const num = URL.indexOf('/api');
    resUrl = URL.substring(0, num);
  } else {
    resUrl = URL;
  }
  
  return resUrl;
 }
