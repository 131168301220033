/**
 * 附近地铁2/公交1
 */

import React, { useState, useEffect } from "react";
import { NavBar, Icon, Tabs, ActivityIndicator, Toast } from "antd-mobile";
import { resetTitle, checkText } from "../../utils/util";
import { getPointsRoutesStation } from '../../services/services';
import "./StationList.scss";

function StationList(props) {
  const stationName = props.location.state.name;
  const pointCode = props.location.state.pointCode;
  const type = props.location.state.type.toString();
  const [loading, setLoading] = useState(false);
  const [stationsList, setStationsList] = useState(null);
  const hightText = '北京南站';

  // 重置title
  useEffect(() => {
    resetTitle();
  },[])

  useEffect(() => {
    setLoading(true);
    let params = {
      code: pointCode,
      name: stationName
    }
    getPointsRoutesStation(params)
    .then(res => {
      setLoading(false);
      if (res.code !== 200) return Toast.fail(res.msg, 1);
      if (!res.data) return Toast.offline("暂无数据", 1);
      setStationsList(res.data);
    }).catch(err => {
      setLoading(false);
    })
  }, [pointCode, stationName]);

  const tabs = [
    { title: "开往" + (stationsList ? stationsList[0].direction : "") },
    { title: "开往" + (stationsList ? stationsList[1].direction : "") }
  ];

  return (
    <div className="station-list">
      <div className="header">
        <NavBar
          mode="light"
          style={{ color: "#333333", fontWeight: "bold" }}
          icon={
            <Icon
              type="left"
              size="lg"
              onClick={() => {
                props.history.goBack();
              }}
            />
          }
        >
          {type === '2' ? stationName : (checkText(stationName, '路') ? stationName : stationName + '路')}
        </NavBar>
      </div>
      <ActivityIndicator toast text="正在加载" animating={loading} />
      <div className="content">
        <Tabs tabs={tabs} initialPage={0}>
          {stationsList
            ? stationsList.map((item, index) => {
                return (
                  <div
                    className="first"
                    style={{
                      display: "flex",
                      backgroundColor: "#fff"
                    }}
                    key={index}
                  >
                    <div className="text-time">
                      <span>首：{item.firstBusTime}</span>
                      <span>末：{item.lastBusTime}</span>
                      <span>
                        发车间隔：
                        {item.intervalTime}分钟
                      </span>
                    </div>
                    <div className="list-wrapper">
                      {item.routeStations
                        ? item.routeStations.map((list, num) => {
                            return (
                              <div className="list-item" key={num}>
                                <div className="item-text">
                                  <div
                                    className="imgIcon"
                                    style={
                                      list.stationName.indexOf(
                                        hightText
                                      ) >= 0
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <img
                                      src={require(`../img/${
                                        type === "2"
                                          ? "subwayHighlight"
                                          : "busHighlight"
                                      }.png`)}
                                      alt=""
                                    />
                                  </div>
                                  <div className="point">
                                    <span
                                      className={
                                        list.stationName.indexOf(
                                          hightText
                                        ) >= 0
                                          ? "active"
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div
                                    className={
                                      list.stationName.indexOf(
                                        hightText
                                      ) >= 0
                                        ? "desc active"
                                        : "desc"
                                    }
                                  >
                                    <span>{num + 1}</span>
                                    <span>{list.stationName}</span>
                                  </div>
                                  <div
                                    className="markIcon"
                                    style={
                                      list.stationName.indexOf(
                                        hightText
                                      ) >= 0
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                    onClick={() => {
                                      window.location.href = `https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.pointName},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0"`;
                                    }}
                                  >
                                    <img
                                      src={require("../img/markHighlight.png")}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="item-line">
                                  <span className="span-line" />
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                );
              })
            : ""}
        </Tabs>
      </div>
    </div>
  );
}

export default StationList;
