import React, { useState, useEffect } from "react";
import "./index.scss";
import { NavBar, Icon, Tabs, Modal } from "antd-mobile";
import { getPointsAll, updatePointsMsg } from '../../services/services';
import { resetTitle } from "../../utils/util";
const prompt = Modal.prompt;

function InfoInput(props) {
  const [tabs] = useState([
    { title: "公交" },
    { title: "出租车" },
    { title: "停车场" },
    { title: "站内人流" }
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    resetTitle();
    getData();
  }, []);

  // 接口请求
  function getData() {
    getPointsAll().then(res => {
      if (res.code === 200 && res.data) {
        setData(res.data);
      } else {
        console.log("error:", res.message);
      }
    })
  }
  function putData(value, code, type) {
    let params = {};
    if (type === 1) params = { queueNum: value };
    if (type === 2) params = { taxiNum: value };
    if (type === 3) params = { queueNum: value };
    if (type === 4) params = { parkingNum: value };
    if (type === 5) params = { stationPeoNum: value };

    const obj = {
      params,
      code
    }
    updatePointsMsg(obj).then(res => {
      if (res.code === 200 && res.data) {
        getData();
      }
    })
  }

  function upDataOnclick(type, item) {
    var title, number, placeholders;
    if (type === 1) {
      title = "当前排队人数";
      number = item.queueNum;
      placeholders = "请输入当前人数";
    } else if (type === 3) {
      title = "当前等待人数";
      number = item.queueNum;
      placeholders = "请输入当前等待人数";
    } else if (type === 4) {
      title = "当前空车位数";
      number = item.parkingNum;
      placeholders = "请输入当前空车位数";
    } else if (type === 5) {
      title = "当前站内人数";
      number = item.stationPeoNum;
      placeholders = "请输入当前站内人数";
    }

    prompt(
      title,
      "",
      [
        { text: "取消" },
        { text: "更新数据", onPress: value => putData(value, item.code, type) }
      ],
      "default",
      number,
      [placeholders]
    );
  }
  return (
    <div className="info-input">
      <NavBar
        className="header"
        mode="light"
        icon={<Icon type="left" size="lg" />}
        onLeftClick={() => props.history.goBack()}
      >
        信息录入
      </NavBar>
      <div className="content">
        <Tabs
          tabs={tabs}
          swipeable={false}
          renderTab={tab => <span>{tab.title}</span>}
        >
          <div className="infoList">
            {data.buses &&
              data.buses.map((item, index) => {
                return (
                  <div key={index} className="list">
                    <p>{item.pointName}</p>
                    <div className="number">
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#333333"
                        }}
                      >
                        当前排队人数：
                      </span>
                      <span
                        style={{
                          float: "right",
                          marginRight: "10px",
                          color: "#999999"
                        }}
                      >
                        <span
                          style={{
                            marginRight: "15px"
                          }}
                        >
                          {item.queueNum ? item.queueNum : 0}人
                        </span>
                        <span
                          className="update"
                          onClick={() => upDataOnclick(1, item)}
                        >
                          更新
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="infoList">
            {data.taxis &&
              data.taxis.map((item, index) => {
                return (
                  <div key={index} className="list">
                    <p>{item.pointName}</p>
                    <div className="number">
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#333333"
                        }}
                      >
                        当前等待人数：
                      </span>
                      <span
                        style={{
                          float: "right",
                          marginRight: "10px",
                          color: "#999999"
                        }}
                      >
                        <span
                          style={{
                            marginRight: "15px"
                          }}
                        >
                          {item.queueNum ? item.queueNum : 0}人
                        </span>
                        <span
                          className="update"
                          onClick={() => upDataOnclick(3, item)}
                        >
                          更新
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="infoList">
            {data.parkings &&
              data.parkings.map((item, index) => {
                return (
                  <div key={index} className="list">
                    <p>{item.pointName}</p>
                    <div className="number">
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "#333333"
                        }}
                      >
                        空车位数：
                      </span>
                      <span
                        style={{
                          float: "right",
                          marginRight: "10px",
                          color: "#999999"
                        }}
                      >
                        <span
                          style={{
                            marginRight: "15px"
                          }}
                        >
                          {item.parkingNum ? item.parkingNum : 0}
                        </span>
                        <span
                          className="update"
                          onClick={() => upDataOnclick(4, item)}
                        >
                          更新
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="infoList">
            {data.stations &&
              data.stations.map((item, index) => {
                return (
                  <div key={index} className="flowrate">
                    <span
                      style={{
                        marginLeft: "10px",
                        color: "#333333"
                      }}
                    >
                      当前站内人数约为：
                    </span>
                    <span
                      style={{
                        float: "right",
                        marginRight: "10px",
                        color: "#999999"
                      }}
                    >
                      <span
                        style={{
                          marginRight: "15px"
                        }}
                      >
                        {item.stationPeoNum ? item.stationPeoNum : 0}人
                      </span>
                      <span
                        className="flowrateUpdate"
                        onClick={() => upDataOnclick(5, item)}
                      >
                        更新
                      </span>
                    </span>
                  </div>
                );
              })}
          </div>
        </Tabs>
      </div>
    </div>
  );
}
export default InfoInput;
