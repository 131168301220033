/**
 * 文字模式
 */
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Toast, NavBar, Icon } from "antd-mobile";
import { getPointsAll } from '../../../services/services';
import { timeFormat } from '../../../utils/util';
import "./text.scss";

function Text(props) {
  const stateType = props.location.state ? props.location.state.type : 'taxi';
  const [loading, setLoading] = useState(false);
  // 出租车、公交车、地铁、站点、停车场的全部数据
  const [allData, setAllData] = useState(null); 

  useEffect(() => {
    getAllData(stateType)
  }, [stateType]);

  //获取数据
  function getAllData(stateType) {
    getPointsAll().then(res => {
      setLoading(false);
        if (res.code !== 200) return Toast.fail(res.msg, 1);
        if (!res.data) return Toast.offline("暂无数据", 1);
        if(stateType === 'taxi') return setAllData({taxis: res.data.taxis});
        if(stateType === 'busSubway') return setAllData({buses: res.data.buses, subways: res.data.subways});
    }).catch(err => {
      setLoading(false);
    })
  }

  const timeDiff = 900000; //15分钟
  const testDescFun = (queueNum, updateTime, firstBusTime, lastBusTime, waitLongTime) => {
    if(!queueNum) return `首班车：${firstBusTime}，末班车：${lastBusTime}`;
    if(!updateTime) return `首班车：${firstBusTime}，末班车：${lastBusTime}`;
    if((new Date().getTime() - new Date(timeFormat(updateTime))) > timeDiff) return `首班车：${firstBusTime}，末班车：${lastBusTime}`;
    return `排队${queueNum}人，预计等候${waitLongTime}分钟`;
  }

  // 显示特定的数据
  const isShowTitle = (value) => {
    if(allData && allData[value]) {
      return {"display": 'block'}
    } else {
      return {"display": 'none'}
    }
  }

  return (
    <div className="text-wrapper">
      <ActivityIndicator toast text="正在加载" animating={loading} />
      <NavBar
        mode="light"
        className='text-title'
        icon={
          <Icon
            type="left"
            size="lg"
            onClick={() => {
              props.history.goBack();
            }}
          />
        }
      >
        {stateType === 'taxi' ? "出租车" : '公交/地铁'}
      </NavBar>
      <h4 className="title-type" style={isShowTitle('taxis')}>出租车</h4>
      {allData && allData.taxis
        ? allData.taxis.map((item, index) => {
            return (
              <div className="type-list" key={index}>
                <div
                  className="type-item"
                  onClick={() => {
                    window.location.href = `https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`;
                  }}
                >
                  <div className="left">
                    <img src={require("../../img/taxi.png")} alt="" />
                  </div>
                  <div className="text">
                    <p>{item.pointName}</p>
                    <span
                      className={
                        item.waitLongTime < 30
                          ? "spanGreen"
                          : item.waitLongTime >= 30 && item.waitLongTime <= 80
                          ? "spanYellow"
                          : "spanRed"
                      }
                    >{`排队${item.queueNum ? item.queueNum : 0}人，预计等候${
                      item.waitLongTime ? item.waitLongTime : 0
                    }分钟`}</span>
                  </div>
                  <div className="right" />
                </div>
              </div>
            );
          })
        : ""}
      <h4 className="title-type" style={isShowTitle('buses')}>公交车</h4>
      {allData && allData.buses
        ? allData.buses.map((item, index) => {
            return (
              <div className="type-list" key={index}>
                <div className="type-item">
                  <div className="left">
                    <img src={require("../../img/bus.png")} alt="" />
                  </div>
                  <div
                    className="text"
                    onClick={() => {
                      props.history.push({
                        pathname: "/nearby-car",
                        state: { code: item.code, type: 1 }
                      });
                    }}
                  >
                    <p>{item.pointName}</p>
                    {testDescFun(item.queueNum, item.updateTime, item.firstBusTime, item.lastBusTime, item.waitLongTime)}
                  </div>
                  <div className="right">
                    <a
                      style={{
                        opacity: 0,
                        display: "block",
                        height: "100%",
                        width: "100%"
                      }}
                      href={`https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`}
                    >
                      占位
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      <h4 className="title-type" style={isShowTitle('ferryCars')}>免费摆渡车</h4>
      {allData && allData.ferryCars
        ? allData.ferryCars.map((item, index) => {
            return (
              <div className="type-list" key={index}>
                <div className="type-item">
                  <div className="left">
                    <img src={require("../../img/bus.png")} alt="" />
                  </div>
                  <div
                    className="text"
                    onClick={() => {
                      props.history.push({
                        pathname: "/nearby-car",
                        state: { code: item.code, type: 9 }
                      });
                    }}
                  >
                    <p>{item.pointName}</p>
                    {testDescFun(item.queueNum, item.updateTime, item.firstBusTime, item.lastBusTime, item.waitLongTime)}
                  </div>
                  <div className="right">
                    <a
                      style={{
                        opacity: 0,
                        display: "block",
                        height: "100%",
                        width: "100%"
                      }}
                      href={`https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`}
                    >
                      占位
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      <h4 className="title-type" style={isShowTitle('subways')}>地铁</h4>
      {allData && allData.subways
        ? allData.subways.map((item, index) => {
            return (
              <div className="type-list" key={index}>
                <div className="type-item">
                  <div className="left">
                    <img src={require("../../img/subway.png")} alt="" />
                  </div>
                  <div
                    className="text"
                    onClick={() => {
                      props.history.push({
                        pathname: "/nearby-car",
                        state: { code: item.code, type: 2 }
                      });
                    }}
                  >
                    <p>{item.pointName}</p>
                    {testDescFun(item.queueNum, item.updateTime, item.firstBusTime, item.lastBusTime, item.waitLongTime)}
                  </div>
                  <div className="right">
                    <a
                      style={{
                        opacity: 0,
                        display: "block",
                        height: "100%",
                        width: "100%"
                      }}
                      href={`https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`}
                    >
                      占位
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default Text;
