const defaultState = {
  guideMode: false
}
export default (state = {...defaultState}, action) => {
  if(action.type === 'goBack'){
    let newState = JSON.parse(JSON.stringify(state)) //深度拷贝state
    newState.guideMode = action.guideMode
    return newState
  }
  if(action.type === 'guide'){
    let newState = JSON.parse(JSON.stringify(state)) //深度拷贝state
    newState.guideMode = action.guideMode
    return newState
  }
  return state
}
