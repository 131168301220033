import React, {useState, useEffect} from 'react'
import {ActivityIndicator} from 'antd-mobile'
import '../css/ConfirmationPage.scss'
import global from '../utils/global'

import moment from "moment/moment";

function SelectInfo() {
    // const SHOPPING_CART_HOST_URL = 'https://kekai.g2work.com/shoppingcart/findMyShopCardAndJoin.action'
    const SHOPPING_CART_HOST_URL = 'https://www.dajiashequ.com/shoppingcart/findMyShopCardAndJoin.action'

    const [trips, setTripsData] = useState({})
    const [commoditiesList, setCommoditiesList] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0)
    const [loading, setLoadingState] = useState(false)

    useEffect(() => {
        let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA);
        let storageTripsDataStr = storageTripsData ? JSON.parse(storageTripsData) : {};
        setTripsData(storageTripsDataStr)

        let storageCommoditiesList = sessionStorage.getItem(global.STORAGE_KEY_COMMODITIES_LIST);
        let storageCommoditiesListStr = storageTripsData ? JSON.parse(storageCommoditiesList) : {};
        setCommoditiesList(storageCommoditiesListStr)
    }, []);

    function goShoppingCart() {
        let skuIDList = []
        let buyNumberList = []

        if (trips && trips.tripPlannings && trips.tripPlannings.length > 0) {
            for (let i = 0; i < trips.tripPlannings.length; i++) {
                if (commoditiesList && commoditiesList.length > 0) {
                    for (let j = 0; j < commoditiesList.length; j++) {
                        let data = trips.tripPlannings[i][commoditiesList[j].categoryId]
                        if (data && data.length > 0) {
                            for (let k = 0; k < data.length; k++) {
                                let index = skuIDList.indexOf(data[k].skuId)
                                if (index > -1) {
                                    buyNumberList[index] += data[k].num
                                } else {
                                    skuIDList.push(data[k].skuId)
                                    buyNumberList.push(data[k].num)
                                }
                            }
                        }
                    }
                }
            }
        }

        let skuIDStr = ''
        let buyNumberStr = ''

        if(skuIDList.length > 0){
            for(let i = 0;i < skuIDList.length ;i++){
                let skuId = skuIDStr === '' ? skuIDList[i] : ',' + skuIDList[i]
                let buyNum = buyNumberStr === '' ? buyNumberList[i] : ',' + buyNumberList[i]

                skuIDStr += skuId
                buyNumberStr += buyNum
            }
        }

        let companyID = trips['companyID'] || ''
        // let access_token = 'f5a7b0c8-9b78-4d19-bc9e-89af75de6a2c'

        let params =
            '?companyID=' + companyID +
            '&skuIDStr=' + skuIDStr +
            '&buyNumberStr=' + buyNumberStr

        setLoadingState(true)
        // window.location.href = SHOPPING_CART_HOST_URL + params // 本窗口打开外链
        window.open(SHOPPING_CART_HOST_URL + params)// 新窗口打开外链
    }

    function getTabItem() {
        return trips.tripPlannings.map((item, index) => {
            return (
                <div
                    className='tab-item'
                    key={index}
                    style={
                        currentIndex === index ?
                            {color: 'white', backgroundColor: '#547efe'} :
                            {color: 'black', backgroundColor: 'white'}
                    }
                    onClick={() => {
                        setCurrentIndex(index)
                    }}>
                    <span>第{index + 1}天</span>
                </div>
            )
        })
    }

    function getTripsContainer() {
        let data = []

        for (let i = 0; i < commoditiesList.length; i++) {
            if (trips.tripPlannings[currentIndex][commoditiesList[i].categoryId] !== undefined &&
                trips.tripPlannings[currentIndex][commoditiesList[i].categoryId] !== null &&
                trips.tripPlannings[currentIndex][commoditiesList[i].categoryId].length > 0) {

                data = [...data, ...trips.tripPlannings[currentIndex][commoditiesList[i].categoryId]]
            }
        }
        // availHeight（屏幕总高度） - 50（日期选择栏）-50（日期高度）-50（底部按钮高度）-10（边距）
        let maxHeight = window.screen.availHeight - 50 - 50 - 50 - 10
        return (
            <div className='confirmation-data-container' style={{maxHeight: maxHeight}}>
                {getTravelItem(data)}
            </div>
        )
    }

    function getTravelItem(data) {
        return data.map((item, index) => {
            let label = item.names.reduce((name, current) => {
                return name += " " + current
            })

            return (
                <div
                    className='travel-item-container'
                    key={index}>
                    {
                        index !== 0 ?
                            <div className='travel-item-line-container'>
                                <span className='travel-item-line'/>
                            </div> : null
                    }

                    <div className='travel-item'>
                        <div className='travel-item-image'>
                            <img src={item.imgSrc} alt=""/>
                        </div>

                        <div className='travel-item-message'>
                            <span className='travel-item-message-name'>{item.title}</span>

                            <span className='travel-item-message-label'>{label}</span>
                        </div>
                    </div>

                    {
                        index !== data.length - 1 ?
                            <div className='travel-item-line-container'>
                                <span className='travel-item-line'/>
                            </div> : null
                    }

                </div>
            )
        })
    }

    return (
        <div className="confirmation-container">
            <ActivityIndicator toast text="正在下单" animating={loading}/>

            <div className='confirmation-top-container'>

                <div className='confirmation-tab-container'>
                    {
                        trips && trips.tripPlannings && trips.tripPlannings.length > 0 ?
                            getTabItem() : null
                    }
                </div>

                <div className='confirmation-date-container'>
                    {
                        trips && trips.tripPlannings && trips.tripPlannings[currentIndex] ?
                            <span
                                className='today'>{moment(trips.tripPlannings[currentIndex].date).format('MM月DD日')}</span>
                            : null
                    }
                </div>

                {
                    trips && trips.tripPlannings && trips.tripPlannings[currentIndex] ?
                        getTripsContainer()
                        : null
                }

            </div>

            <div className='confirmation-buy-btn'>
                <div onClick={() => {
                    goShoppingCart()
                }}>
                    <span>确定购买（￥{trips['totalPrice' || 0]}）</span>
                </div>
            </div>
        </div>
    )
}


export default SelectInfo
