import React from "react";
import '../css/privacy.scss'

function PrivacyPage() {
  return (
    <div className="privacy">
          <div className="title">隐私政策</div>
          <div className="content">
            <p>欢迎您使用铁路旅行！ 铁旅提醒您：铁路旅行由中国铁道旅行社集团有限有限公司（以下简称“中国铁旅”）所有、运营及维护。如果您在中国铁旅提供的移动应用或软件上访问、使用铁路旅行，便视为您接受了本隐私政策。请您仔细阅读以下内容，尤其是其中加粗字体。如果您不同意以下任一内容，请立刻停止访问/使用该任何移动应用或软件。 “您”指自愿接受本隐私政策的用户。中国铁旅深知个人信息对您的重要性，也深知为您的个人信息提供有效保护是中国铁旅依据相关法律必须履行的法定义务，也是中国铁旅的业务健康可持续发展的基石。感谢您对铁路旅行的信任和使用！中国铁旅致力于维持您对中国铁旅的信任，恪守法律规定和中国铁旅对您的承诺，尽全力保证您的个人信息安全和在合理范围内的合理使用。同时，中国铁旅郑重承诺：中国铁旅将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
            <p className="headline">1、隐私政策的确认和同意</p>
            <p>尊重用户个人隐私是中国铁旅的一项基本政策。铁路旅行是用于中国铁旅为客户提供销售服务，以及实现在其门店内为客户提供会员服务业务的应用。您在使用中国铁旅的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并与中国铁旅达成协议且接受所有的服务条款。</p>
            <p>中国铁旅十分注重保护您的个人信息及隐私安全，中国铁旅理解您通过网络向中国铁旅提供信息是建立在对中国铁旅信任的基础上，中国铁旅重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。如果您对中国铁旅的隐私政策有任何的疑问或建议，请致电服务热线：400-677-6186。</p>
            <p className="headline">2、收集信息</p>
            <p>（1）为了更好地为您提供服务，中国铁旅将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。考虑到中国铁旅会员服务的重要性，您同意，中国铁旅将会收集您在中国铁旅网上输入的或者以其他方式提供给中国铁旅的所有信息：</p>
            <p>•	在您注册为中国铁旅会员时，需提供姓名、手机号等信息。</p>
            <p>•	在您后续更新个人信息时，需符合及时、详尽、准确的要求。</p>
            <p>•	在您预订铁旅服务项目时，需提供使用人姓名、手机号以及联系人姓名、手机号等信息。</p>
            <p>•	在中国铁旅提供其他服务（如购物）时，也可能需要您配合提供的其他个人信息。</p>
            <p>（2）您可以通过中国铁旅为其他人预订，您需要提交该旅客的个人信息，向中国铁旅提供该旅客的个人信息之前，您确保您已经取得其他旅客本人的同意，并确保其已知晓并接受本隐私政策。</p>
            <p>（3）中国铁旅将在您使用中国铁旅服务过程中，自动收集个人信息：</p>
            <p>•	日志信息，指您使用中国铁旅的服务时，系统可能通过cookies、web beacon或其他方式自动采集的信息，包括：</p>
            <p>•	设备信息或软件信息，具体例如您的IP地址和移动设备所用的版本和设备识别码（IDFA/IMEI）、以及通过网页浏览器、移动设备用于接入中国铁旅服务的配置信息。</p>
            <p>•	在使用中国铁旅服务时搜索或浏览的信息，具体例如您使用的网页或APP搜索词语、访问页面，以及您在使用中国铁旅服务时浏览或提供的其他信息。</p>
            <p>•	您通过中国铁旅的服务分享的内容所包含的信息，例如拍摄或上传的共享照片或评论，以及日期、时间等。</p>
            <p>•	位置信息，指您开启设备定位功能并使用中国铁旅基于位置提供的相关服务时，收集的有关您的位置信息，例如通过GPS或WLAN等方式收集您的地理位置信息，或您提供账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息等。您可以通过关闭定位功能，停止对您的地理位置信息的收集。</p>
            <p className="headline">3、使用规则</p>
            <p>（1）用户在使用铁旅会员服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
            <p>上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</p>
            <p>•	反对宪法所确定的基本原则的；</p>
            <p>•	危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
            <p>•	损害国家荣誉和利益的；</p>
            <p>•	煽动民族仇恨、民族歧视、破坏民族团结的；</p>
            <p>•	破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p>•	散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p>•	散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
            <p>•	侮辱或者诽谤他人，侵害他人合法权利的；</p>
            <p>•	含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
            <p>•	含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
            <p>•	不得为任何非法目的而使用网络服务系统；</p>
            <p>•	不利用铁旅服务从事以下活动：</p>
            <p>•	未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
            <p>•	未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
            <p>•	未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
            <p>•	故意制作、传播计算机病毒等破坏性程序的；</p>
            <p>•	其他危害计算机信息网络安全的行为。</p>
            <p>（2）用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿铁旅与合作公司、关联公司，并使之免受损害。对此，铁旅有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收铁旅帐号、追究法律责任等措施。对恶意注册铁旅帐号或利用铁旅帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，铁旅有权回收其帐号。同时，铁旅公司会视司法部门的要求，协助调查。</p>
            <p>（3）用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
            <p>（4）用户须对自己在使用铁旅服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在铁旅公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予铁旅公司等额的赔偿。</p>
            <p className="headline">4、Cookie的使用</p>
            <p>Cookie是网页服务器放在您访问设备上的文本文件，会帮助您在后续访问时调用信息，简化记录您填写个人信息的流程。您有权接受或拒绝Cookie，如果浏览器自动接收Cookie，您可以根据自己的需要修改浏览器的设置以拒绝Cookie。请注意，如果您选择拒绝Cookie，那么您可能无法完全体验中国铁旅提供的服务。</p>
            <p className="headline">5、信息事件的处理</p>
            <p>在不幸发生个人信息安全事件后，中国铁旅将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、中国铁旅已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况中国铁旅将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，中国铁旅会采取合理、有效的方式发布公告。同时，中国铁旅还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
            <p className="headline">6、用户信息管理</p>
            <p>（1）您可以随时登录“账户管理”个人中心、常用信息等，查询、更正及删除自己的账户信息，修改个人资料、隐私设置、安全设置，修改收货地址等使用中国铁旅服务时您提供的个人信息，如有问题，可以联系中国铁旅客服400-677-6186。</p>
            <p>（2）您可通过如下路径注销铁路旅行账户：拨打中国铁旅官网公布的客服电话(400-677-6186)，根据语音提示转至客服人工服务，由客服验证后完成账号的注销。注销中国铁旅网账户后，您的账号将被立即停用，中国铁旅将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息中国铁旅仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。(400-677-6186)，根据语音提示转至客服人工服务，由客服验证后完成账号的注销。注销中国铁旅网账户后，您的账号将被立即停用，中国铁旅将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息中国铁旅仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。</p>
            <p className="headline">7、用户信息权益</p>
            <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，中国铁旅保障您对自己的个人信息行使以下权利：</p>
            <p>（1）访问您的个人信息</p>
            <p>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</p>
            <p>账户信息——您可以通过“账户管理”访问或编辑您的账户中的个人基本资料信息、联系方式信息、更改您的密码、进行账户关联、身份认证等。 如果您无法通过上述链接访问个人信息，您可以通过中国铁旅客服400-677-6186，随时与中国铁旅联系。</p>
            <p>（2）更正您的个人信息</p>
            <p>您发现中国铁旅处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过中国铁旅官网公布的客服电话(400-677-6186)随时与中国铁旅联系。为保障安全，中国铁旅将在您行使更正权前对您的身份进行验证。</p>
            <p>（3）删除您的个人信息</p>
            <p>在以下情形中，您可以向中国铁旅提出删除个人信息的请求，您可以通过中国铁旅官网公布的客服电话随时与中国铁旅联系：</p>
            <p>•	a). 如果中国铁旅违反法律法规或与您的约定之外的收集、使用、与他人共享或转让您的个人信息；</p>
            <p>•	b). 如果中国铁旅违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求中国铁旅立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</p>
            <p>当您从中国铁旅的服务中删除信息后，中国铁旅可会立即删除您的个人信息，中国铁旅将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息中国铁旅仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。</p>
            <p>（4） 响应您的上述请求</p>
            <p>为保障安全，您需要提供书面请求，或以其他方式证明您的身份。中国铁旅可能会先要求您验证自己的身份，然后再处理您的请求。</p>
            <p>对于您合理的请求，中国铁旅原则上不收取费用，但对多次重复、超出合理限度的请求，中国铁旅将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁盘上存放的信息）的请求，中国铁旅可能会予以拒绝。</p>
            <p>在以下情形中，按照法律法规要求，中国铁旅将无法响应您的上述请求：</p>
            <p>•	a). 与国家安全、国防安全直接相关的；</p>
            <p>•	b). 与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>•	c). 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
            <p>•	d). 中国铁旅有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；</p>
            <p>•	e). 响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
            <p>•	f). 涉及商业秘密的。</p>
            <p className="headline">8、隐私政策的修订</p>
            <p>（1）本服务条款的订立、履行、解释及争议的解决均适用中华人民共和国法律。</p>
            <p>（2）如果您就本服务的使用与本公司发生争议，双方应尽量友好协商解决；协商不成，您不可撤销地同意将该等争议排他地提交至本公司住所地的人民法院通过诉讼的方式解决。</p>
            <p>（3）本公司未行使或执行本服务条款项下的任何权利，不构成对该等权利之放弃。</p>
            <p>（4）如本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其他条款仍有效并具有拘束力。</p>
            <p>（5）本服务条款英文翻译仅供参考，如本服务条款中英文内容存在差异，以中文作准。</p>
            <p>（6）本公司保留本服务条款之解释权。</p>
          </div>
      </div>
  );
}

export default PrivacyPage;
