import React, { useState } from 'react'
import '../css/index.scss'
import homeImage from '../image/home.png'
import expect from '../image/expect.png'
import addIcon from '../image/add_home.png'

function Index(props) {
	const [pressFirst, setPressFirst] = useState(true)

	// useEffect(() => {
	//     if (reactEnv.watermark.enabled === 'true') {
	//         watermark({
	//             watermark_txt0: reactEnv.watermark.title,
	//             watermark_txt1: reactEnv.watermark.text,
	//             watermark_txt2: reactEnv.buildTime
	//         });
	//     }
	// }, []);

	return (
		<div className="home-container">
			<div className="tab-bar">
				<div
					className={pressFirst ? 'tab-bar-btn-press' : 'tab-bar-btn-un-press'}
					onClick={() => {
						setPressFirst(true)
					}}
				>
					<span>市郊铁路怀密线</span>
					{pressFirst ? <span className="line" /> : null}
				</div>
				<div
					className={!pressFirst ? 'tab-bar-btn-press' : 'tab-bar-btn-un-press'}
					onClick={() => {
						setPressFirst(false)
					}}
				>
					<span>市郊铁路S2线</span>
					{!pressFirst ? <span className="line" /> : null}
				</div>
			</div>

			{pressFirst ? (
				<div className="tab-container">
					<img src={homeImage} alt="" className="home-image" />
					<div
						className="home-container-btn"
						onClick={() => {
							props.history.push({ pathname: '/select_route' })
						}}
					>
						<img src={addIcon} alt="" className="home-container-btn-add-icon" />
						<span className="home-container-btn-txt">开始定制行程</span>
					</div>

					<div className="tab-container" />
				</div>
			) : (
				<div className="main-right">
					<img className="img" src={expect} alt="" />
					<span className="font_1">更多功能正在开发中...</span>
					<span className="font_2">敬请期待...</span>
				</div>
			)}
		</div>
	)
}

export default Index
