import React, { useState, useEffect } from "react";
import lodash from "lodash";
import "../css/orderEliminate.scss";
import api from "../utils/api";
import select from "../image/select.png";
import no_select from "../image/no_select.png";
import yetSelect from "../image/yet_select.png";
import { Toast, ActivityIndicator } from "antd-mobile";

function OrderEliminatePage(props) {
  const [dataSource, setDataSource] = useState();
  const [loading, setLoadingState] = useState(false);
  const [isEliminate, setIsEliminate] = useState(false);

  function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  }

  let code = getQueryString("code");

  useEffect(() => {
    setLoadingState(true);
    fetch(api.base + api.eliminateList + code)
      .then(response => response.json())
      .then(res => {
        setLoadingState(false);
        if (res.code === 200) {
          if (res.data) {
            var source =
              res.data.orderDetailsResList &&
              res.data.orderDetailsResList.length >= 1
                ? res.data.orderDetailsResList.map(item => {
                    if (res.data.refund) {
                      if (!res.data.apply) {
                        item.isShowSelect = false;
                        item.yetSelect = false;
                      }else{
                        if (item.allowUsed && !item.used) {
                          //可选  （允许核销 && 未核销）
                          item.selcetItem = false;
                          item.isShowSelect = true;
                          item.yetSelect = false;
                          setIsEliminate(true);
                        } else if (item.allowUsed && item.used) {
                          // 不可选   （允许核销 && 已核销）
                          item.selcetItem = true;
                          item.yetSelect = true;
                          item.isShowSelect = true;
                        } else {
                          //不显示  （不允许核销）
                          item.isShowSelect = false;
                          item.yetSelect = false;
                        }
                      }
                    } else {
                      if (item.allowUsed && !item.used) {
                        //可选  （允许核销 && 未核销）
                        item.selcetItem = false;
                        item.isShowSelect = true;
                        item.yetSelect = false;
                        setIsEliminate(true);
                      } else if (item.allowUsed && item.used) {
                        // 不可选   （允许核销 && 已核销）
                        item.selcetItem = true;
                        item.yetSelect = true;
                        item.isShowSelect = true;
                      } else {
                        //不显示  （不允许核销）
                        item.isShowSelect = false;
                        item.yetSelect = false;
                      }
                    }
                    return item;
                  })
                : null;
            res.data.orderDetailsResList = source;
            setDataSource(res.data);
          } else {
            Toast.info("此核销单暂无数据");
          }
        } else {
          Toast.info(res.message);
        }
      });
  }, [code]);

  function changeSelectStatus(item, index) {
    var itemSource = dataSource.orderDetailsResList[index];
    itemSource.selcetItem = !item.selcetItem;
    dataSource.orderDetailsResList[index] = itemSource;
    let jsonStr = JSON.stringify(dataSource);
    setDataSource(JSON.parse(jsonStr));
  }
  function eliminateOrder() {
    var idsArr =
      dataSource.orderDetailsResList &&
      dataSource.orderDetailsResList.map(item => {
        if (item.selcetItem && item.allowUsed && !item.used) {
          return item.id;
        } else {
          return undefined;
        }
      });
    for (var s = 0; s < idsArr.length; s++) {
      if (typeof idsArr[s] == "undefined") {
        idsArr.splice(s, 1);
        s = s - 1;
      }
    }
    if (idsArr.length <= 0) {
      Toast.info("请选择核销数据");
      return;
    }
    setLoadingState(true);
    var jsonheader = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    var options = lodash.extend(jsonheader, {
      body: JSON.stringify(idsArr)
    });
    fetch(api.base + api.eliminateOrder, options)
      .then(response => response.json())
      .then(res => {
        setLoadingState(false);
        if (res.code === 200) {
          props.history.push("/order_eliminate_succeed");
        } else {
          Toast.info(res.message || res.msg);
        }
      });
  }

  return (
    <div className="order-list-container ">
      <ActivityIndicator toast text="正在加载" animating={loading} />

      <div className="eliminat-container">
        <div className="title-view">
          <span className="name-font">
            {dataSource ? dataSource.contactName : ""}
          </span>
          <span className="phone-number-font">
            {dataSource ? dataSource.contactPhone : ""}
          </span>
        </div>
        <div className="list-container">
          {dataSource &&
          dataSource.orderDetailsResList &&
          dataSource.orderDetailsResList.length
            ? dataSource.orderDetailsResList.map((item, index) => {
                return (
                  <div
                    className="list-item-container"
                    key={index}
                    onClick={() => {
                      item.allowUsed && !item.used
                        ? changeSelectStatus(item, index)
                        : console.log("不让选");
                    }}
                  >
                    <img
                      src={item ? item.picAddr : ""}
                      alt=""
                      className="item-icon"
                    />
                    <div className="list-item-title-container">
                      <div className="list-item-synopsis-container">
                        <span className="list-item-title-font">
                          {item ? item.commodityName : ""}
                        </span>
                      </div>
                      <div className="eliminat-item-synopsis-container">
                        <span className="list-item-synopsis-font">
                          {item ? item.subCommodityName : ""}
                        </span>
                        <span className="eliminat-item-synopsis-font">
                          ¥{item ? item.price : ""}
                        </span>
                      </div>
                    </div>
                    {item.isShowSelect ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item.yetSelect ? (
                          <img
                            src={yetSelect}
                            alt=""
                            style={{ width: 15, height: 15 }}
                          />
                        ) : (
                          <img
                            src={item.selcetItem ? select : no_select}
                            alt=""
                            style={{ width: 15, height: 15 }}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
        <div className="order-info-view">
          <div className="order-info-title-view">
            <span className="order-info-title-font">订单编号：</span>
            <span className="order-info-title-font">下单时间：</span>
          </div>
          <div className="order-info-title-view">
            <span className="order-info-title-font">
              {dataSource ? dataSource.orderNo : ""}
            </span>
            <span className="order-info-title-font">
              {dataSource ? dataSource.createTime : ""}
            </span>
          </div>
        </div>
      </div>

      {isEliminate ? (
        <div
          className="ss-next-btn-container"
          onClick={() => {
            eliminateOrder();
          }}
        >
          核销
        </div>
      ) : null}
    </div>
  );
}

export default OrderEliminatePage;
