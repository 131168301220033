import React, { useState, useEffect } from "react";
import "./index.scss";
import { Tabs, ActivityIndicator } from "antd-mobile";
import { resetTitle } from "../../utils/util";
import { getStationScreen } from "../../services/services";

var timer = null;

function StationScreen(props) {
  const [tabs] = useState([
    {
      title: "到达"
    },
    {
      title: "候车"
    }
  ]);
  const [startData, setstartData] = useState([]);
  const [endData, setednData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    resetTitle();
    setLoading(true);
    getData();
    timer = setInterval(() => {
      getData();
    }, 60000);
    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, []);

  // 接口请求
  function getData() {
    const code = "VNP";
    getStationScreen(code).then(res => {
      setLoading(false);
      if (res.code === 200 && res.data) {
        setstartData(res.data.arriveInfo);
        setednData(res.data.waitInfo);
      }
    });
  }

  return (
    <div className="station-screen">
      {/* <NavBar
       className="header"
       mode="light"
       icon={<Icon type="left" size="lg" />}
       onLeftClick={() => {
         props.history.goBack();
         clearInterval(timer);
         timer = null;
       }}
     >车站大屏</NavBar> */}
      <div className="content">
        <Tabs
          tabs={tabs}
          swipeable={false}
          renderTab={tab => <span>{tab.title}</span>}
        >
          <div className="info">
            <div className="names">
              <span>车次</span>
              <span>终点站</span>
              <span>到站点</span>
              <span>出站口</span>
              <span>状态</span>
            </div>
            {startData
              ? startData.map((list, index) => {
                  return (
                    <div key={index} className="listNames">
                      <span>{list.trainNo ? list.trainNo : "-"}</span>
                      <span>{list.station ? list.station : "-"}</span>
                      <span>{list.time ? list.time : "-"}</span>
                      <span>-</span>
                      <span
                        style={{
                          color: list.status === "正点" ? "#323232" : "#DF0909"
                        }}
                      >
                        {list.status ? list.status : "-"}
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="info">
            <div className="names">
              <span>车次</span>
              <span>终点站</span>
              <span>发车点</span>
              <span>检票口</span>
              <span>状态</span>
            </div>
            {endData
              ? endData.map((list, index) => {
                  return (
                    <div key={index} className="listNames">
                      <span>{list ? list.trainNo : "-"}</span>
                      <span>{list ? list.station : "-"}</span>
                      <span>{list ? list.time : "-"}</span>
                      <span>{list ? list.gateNo : "-"}</span>
                      <span
                        style={{
                          color:
                            list.status === "停止检票"
                              ? "#DF0909"
                              : list.status === "正在检票"
                              ? "#33AC1C"
                              : "#323232"
                        }}
                      >
                        {list ? list.status : "-"}
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        </Tabs>
      </div>
      <ActivityIndicator toast text="正在加载" animating={loading} />
    </div>
  );
}

export default StationScreen;
