/**
 * 附近地铁2/公交1
 */

import React, { useState, useEffect } from "react";
import { NavBar, Icon, ActivityIndicator, Toast } from "antd-mobile";
import { urlParse, resetTitle, checkText } from '../../utils/util';
import { getPointsRoutes } from '../../services/services';
import "./NearbyCar.scss";
import store from '../../store';

function NearbyCar(props) {
  const searchObj = urlParse(props.location.search);

  const type = props.location.state ? props.location.state.type.toString() : searchObj.type.toString();
  const code = props.location.state ? props.location.state.code : searchObj.code;

  const [loading, setLoading] = useState(false);
  const [routesData, setRoutesData] = useState(null);

  useEffect(() => {
    resetTitle();
    setLoading(true);
    getPointsRoutes(code).then(res => {
      setLoading(false);
      if (res.code !== 200) return Toast.fail(res.msg, 1);
      if (res.data.length === 0 || !res.data)
        return Toast.offline("暂无数据", 1);
      setRoutesData(res.data);
    }).catch(err => {
      setLoading(false);
    })
  }, [code]);

  return (
    <div className="nearby-car">
      <NavBar
        mode="light"
        style={{color: '#333333', fontWeight: 'bold'}}
        icon={
          <Icon
            type="left"
            size="lg"
            onClick={() => {
              const action ={
                type:'goBack',
                guideMode: true
              }
              store.dispatch(action)
              props.history.goBack();
            }}
          />
        }
      >
        {type === '2' ? "地铁" : type === '1' ? '公交' : '免费摆渡车'}
      </NavBar>
      <ActivityIndicator toast text="正在加载" animating={loading} />
      <div className="content">
        <h4 className="content-title">
          附近{type === '2' ? "地铁" : type === '1' ? '公交' : '免费摆渡车'}
        </h4>
        <div className="content-list">
          {routesData
            ? routesData.map((item, index) => {
              console.log(item)
              var isOperate =  item.isOperate === false ? '(非运营时间)' : '';
                return (
                  <div
                    className="content-item"
                    key={index}
                    onClick={() => {
                      props.history.push({
                        pathname: "/station-list",
                        state: { pointCode: item.pointCode, name: item.name, type: type}
                      });
                    }}
                  >
                    <div className="text">
                      <p>{
                        type === '2' ?
                         item.name
                         : (checkText(item.name, '路')
                         ? item.name
                         : item.name + '路')}
                         <span style={{
                           color: 'red',
                           fontSize: '13px'
                         }}>
                         {isOperate}
                         </span>
                         </p>
                      <span>
                        <i>首：{item.firstBusTime}</i>
                        <i>末：{item.lastBusTime}</i>
                      </span>
                    </div>
                    <div className="right">
                      <Icon type="right" />
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
}

export default NearbyCar;
