import React from 'react';
import './index.scss';

function freeCar() {
  return (
    <div className="free-car">
      <div>
        <img alt="" src={require('./img/1.jpg')}/>
      </div>
      <div style={{'marginTop': '-4px'}}>
      <img alt="" src={require('./img/2.jpg')}/>
      </div>
    </div>
  )
}
export default freeCar;
