import React, { useState, useEffect } from "react";
import "../css/SelectTrainTicket.scss";
import ListItem from "../components/ListItem";
import api from "../utils/api";
import moment from "moment";
import global from '../utils/global'

import {
  ListView,
  Icon,
  PullToRefresh,
  ActivityIndicator,
  Toast
} from "antd-mobile";

function SelectTrainTicket(props) {
  const ds = new ListView.DataSource({
    rowHasChanged: (r1, r2) => r1 !== r2
  });
  const [dataSource] = useState(ds);
  const [list, setList] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState();
  const [loading, setLoadingState] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [pullLoading, setPullLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  let categoryId = props.location.state
    ? props.location.state.categoryId
    : null;

  const DATE_FORMAT = "YYYY-MM-DD";
  let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA);
  let storageTripsDataStr = storageTripsData
    ? JSON.parse(storageTripsData)
    : {};  
  const startDate = moment(storageTripsDataStr.departDate).format(DATE_FORMAT);
  const endDate = moment(storageTripsDataStr.returnDate).format(DATE_FORMAT);

  useEffect(() => {
    setLoadingState(true);
    fetch(
      api.base +
        api.commoditiesList +
        categoryId +
        "?pageNo=" +
        1 +
        "&pageSize=" +
        10 +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    )
      .then(response => response.json())
      .then(res => {
        setNoData(true);
        setLoadingState(false);
        if (res.code === 200) {
          setList(res.data.dataList);
          setCurrentPageNumber(res.data.currentPageNumber);
          setTotalPageSize(res.data.totalPageSize);
        } else {
          Toast.info(res.message);
        }
      });
  }, [categoryId, endDate, startDate]);

  function requestInfo(page) {
    setLoadingState(true);
    fetch(
      api.base +
        api.commoditiesList +
        categoryId +
        "?pageNo=" +
        page +
        "&pageSize=" +
        10 +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    )
      .then(response => response.json())
      .then(res => {
        setNoData(true);
        setLoadingState(false);
        setUpLoading(false);
        setPullLoading(false);
        if (res.code === 200) {
          if (page !== 1) {
            setCurrentPageNumber(res.data.currentPageNumber);
            setTotalPageSize(res.data.totalPageSize);
            setList([...list, ...res.data.dataList]);
          } else {
            setCurrentPageNumber(res.data.currentPageNumber);
            setTotalPageSize(res.data.totalPageSize);
            setList(res.data.dataList);
          }
        } else {
          Toast.info(res.message);
        }
      });
  }

  //上拉加载
  function onEndReached(page, lastPage) {
    //当前页小于总页数继续请求下一页数据，否则停止请求数据
    if (Number(page) < Number(lastPage)) {
      setUpLoading(true);
      page++;
      requestInfo(page);
    } else {
      Toast.info("没有更多数据了...");
    }
  }

  //下拉刷新
  function onRefresh() {
    setPullLoading(true);
    requestInfo(1);
  }

  function renderRow(item, i) {
    return <ListItem data={item} path={getDetailsPath(item.productId)} />;
  }

  function getDetailsPath(code) {
    var date = props.location.state.date ? props.location.state.date : "";
    return {
      pathname: "details",
      state: { code: code, date: date, categoryId: categoryId }
    };
  }

  return (
    <div className="selcet-train-ticket-container">
      <ActivityIndicator toast text="正在加载" animating={loading} />
      <div>
        {/* <header className="app-header">
          <div>
            <Link
              style={{ marginLeft: 10 }}
              onClick={() => {
                props.history.goback();
              }}
            >
              back
            </Link>
            添加火车票
          </div>
        </header> */}
        <div>
          {list && list.length > 0 ? (
            <ListView
              dataSource={dataSource.cloneWithRows(list)}
              renderRow={(rowData, id1, i) => renderRow(rowData, i)}
              renderFooter={() => (
                <div style={{ padding: 30, textAlign: "center" }}>
                  {currentPageNumber < totalPageSize && upLoading ? (
                    <Icon type="loading" />
                  ) : currentPageNumber === totalPageSize ? null : (
                    <div
                      onClick={() => {
                        onEndReached(currentPageNumber, totalPageSize);
                      }}
                    >
                      加载更多
                    </div>
                  )}
                </div>
              )}
              // onEndReached={() =>
              //   onEndReached(currentPageNumber, totalPageSize)
              // }
              // onEndReachedThreshold={10}
              // scrollRenderAheadDistance={500}
              // onScroll={() => {
              //   console.log("scroll");
              // }}
              useBodyScroll={true}
              className="list-view"
              pullToRefresh={
                <PullToRefresh
                  refreshing={pullLoading}
                  onRefresh={() => onRefresh()}
                />
              }
            />
          ) : noData ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>暂无数据</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SelectTrainTicket;
