import React, { useState, useEffect } from 'react';
import { resetTitle, urlParse } from '../../utils/util';
import { getOtherServices } from '../../services/services'
import './index.scss';
// type = 4, 站内取票
// type = 8, 市内取票
// type = 5, 小红帽

function ListFile(props) {
  const searchObj = urlParse(props.location.search);
  const type = props.location.state ? (props.location.state.type ? props.location.state.type.toString() : '4') : (searchObj.type ? searchObj.type.toString() : '4');
  const [listData, setListData] = useState([]);

  useEffect( () => {
    resetTitle();
    const params = type === '4' ? type + ',8' : type;
    getOtherServices(params).then(res => {
      if(res.code === 200 && res.data) {
        setListData(res.data)
      }
    })
  }, [type])

  return (
    <div className="list-file">
      {/* <NavBar
       className="header"
       mode="light"
       icon={<Icon type="left" size="lg" />}
       onLeftClick={() => props.history.goBack()}
     >{type === '4' ? '站内取票' : '小红帽'}</NavBar> */}
      <div className="content">
        {listData ?
          listData.map( (item, index) => {
            return (
              <div key={index} className="box">
                <div className='img-wrapper'>
                  <img className="img" src={require(`../img/${type === '4' ? 'ticketCollection' : 'smallRedHat'}.png`)} alt="tu" />
                </div>
                <div className="right">
                  <p className="p">{item.pointName}</p>
                  <div className="p bottom margin">
                    <span>营业时间：{item.businessHours}</span>
                  </div>
                  <div className="p bottom">
                    <span className="name">位置：{item.address}</span>
                  </div>
                </div>
                <div className="flx" >
                  <a href={`https://uri.amap.com/navigation?我的位置&to=${item.longitude},${item.latitude},${item.address},midwaypoint&mode=walk&policy=1&src=mypage&coordinate=gaode&callnative=0`}><img className="position" src={require(`../img/mark.png`)} alt="占位"/></a>
                </div>
              </div>
            )
          }) : null
        }
      </div>
    </div>
  )
}
export default ListFile;
