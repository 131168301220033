import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/orderDetails.scss";
import icon from "../image/home.png";
import api from "../utils/api";

function OrderDetailsPage() {
  var goback = {
    // pathname: "/select_service"
  };

  useEffect(() => {
    getRequest();
  }, []);

  // 接口请求
  function getRequest() {
    fetch(api.base + api.orderList + "YT598502959705231360")
      .then(response => response.json())
      .then(res => {
        if (res.code === 200) {
          console.log(res);
        } else {
          console.log("error:", res.message);
        }
      });
  }

  return (
    <div className="order-list-container ">
      <div>
        <header className="app-header">
          <Link to={goback}>back</Link>
          <span>详情</span>
        </header>
        <div className="order-details-container">
          <img src={icon} alt="" className="order-details-img" />
          <div className="order-details-title-container">
            <div className="order-details-title">雁栖湖门票</div>
            <div className="order-details-info">
              <span className="order-details-price">
                ¥ <span className="order-details-price-number">40</span>
              </span>
              <span className="order-details-phone">400-123-321</span>
            </div>
          </div>
          <div className="order-details-content">富文本</div>
          {/* <div dangerouslySetInnerHTML={{
              __html: 你的html字符串
            }}/> */}
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsPage;
