import React, {useState, useEffect} from 'react'
import {Icon, Toast, Modal, ActivityIndicator} from 'antd-mobile';
import {Link} from 'react-router-dom'
import '../css/SelectService.scss'

import moment from 'moment/moment'
import 'moment/locale/zh-cn'
import api from '../utils/api'
import global from '../utils/global'

import addIcon from '../image/add_shop.png'
import subtractIcon from '../image/subtract_shop.png'

const alert = Modal.alert;

function SelectService(props) {
    const [trips, setTripsData] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0)
    const [commoditiesList, setCommoditiesList] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [loading, setLoadingState] = useState(false)

    useEffect(() => {
        let state = props.location.query
        let storagePageType = sessionStorage.getItem(global.STORAGE_KEY_PAGE_TYPE);
        let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA);
        let storageTripsDataStr = storageTripsData ? JSON.parse(storageTripsData) : '';

        let storageAddTripsData = sessionStorage.getItem(global.STORAGE_KEY_ADD_TRIPS_DATA);
        let storageAddTripsDataStr = storageAddTripsData ? JSON.parse(storageAddTripsData) : '';

        if (storagePageType === '1') {// 从时间选择页跳转过来
            let departDate = state.departDate
            let returnDate = state.returnDate
            if (returnDate === undefined || returnDate === null) {
                returnDate = departDate
            }

            let time = returnDate.getTime() - departDate.getTime()
            let tripsData = []
            for (let i = 0; i <= time; i += 86400000) {
                let date = departDate.getTime() + i

                let trip = {
                    date: date,
                    todayTripsTotalNum: 0
                }
                tripsData.push(trip)
            }

            let data = {
                departDate: departDate,
                returnDate: returnDate,
                adultNum: state.adultNum,
                childNum: state.childNum,
                tripPlannings: tripsData,
            }
            setTripsData(data)
            sessionStorage.setItem(global.STORAGE_KEY_PAGE_TYPE, '')
        } else if (storageAddTripsDataStr !== undefined &&
            storageAddTripsDataStr !== null &&
            storageAddTripsDataStr !== '' &&
            storageAddTripsDataStr.params !== undefined &&
            storageAddTripsDataStr.params !== null &&
            storageAddTripsDataStr.params.length > 0) {

            if (storageTripsDataStr &&
                storageTripsDataStr.tripPlannings !== null &&
                storageTripsDataStr.tripPlannings.length > 0) {

                for (let tripPlanningsIndex = 0; tripPlanningsIndex < storageTripsDataStr.tripPlannings.length; tripPlanningsIndex++) {
                    for (let addIndex = 0; addIndex < storageAddTripsDataStr.params.length; addIndex++) {
                        let currentMonth = moment(storageTripsDataStr.tripPlannings[tripPlanningsIndex].date).get('month')
                        let currentDate = moment(storageTripsDataStr.tripPlannings[tripPlanningsIndex].date).get('date')

                        let addDataMonth = moment(storageAddTripsDataStr.params[addIndex].names[0], 'MM月DD日').get('month')
                        let addDataDate = moment(storageAddTripsDataStr.params[addIndex].names[0], 'MM月DD日').get('date')

                        if (currentMonth === addDataMonth && currentDate === addDataDate) {
                            let tripsList = storageTripsDataStr.tripPlannings[tripPlanningsIndex];

                            if (tripsList[storageAddTripsDataStr.categoryId] === undefined ||
                                tripsList[storageAddTripsDataStr.categoryId] === null ||
                                tripsList[storageAddTripsDataStr.categoryId].length === 0) {
                                let totalNum = storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum
                                totalNum += storageAddTripsDataStr.params[addIndex].num
                                storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum = totalNum
                                storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId] = [storageAddTripsDataStr.params[addIndex]]
                                storageAddTripsDataStr.params.splice(addIndex, 1)
                            } else {
                                let totalNum = storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum
                                let categoryList = storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId]
                                totalNum += storageAddTripsDataStr.params[addIndex].num

                                for (let j = 0; j < categoryList.length; j++) {

                                    if (categoryList[j].skuId === storageAddTripsDataStr.params[addIndex].skuId) {

                                        storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId][j].num += storageAddTripsDataStr.params[addIndex].num
                                        storageAddTripsDataStr.params.splice(addIndex, 1)
                                    }
                                }
                                storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum = totalNum
                            }
                        }
                    }
                }

                if (storageAddTripsDataStr.params !== undefined && storageAddTripsDataStr.params.length > 0) {
                    for (let tripPlanningsIndex = 0; tripPlanningsIndex < storageTripsDataStr.tripPlannings.length; tripPlanningsIndex++) {
                        if (storageTripsDataStr.tripPlannings[tripPlanningsIndex].date === storageAddTripsDataStr.date) {
                            setCurrentIndex(tripPlanningsIndex)

                            let tripsList = storageTripsDataStr.tripPlannings[tripPlanningsIndex];
                            if (tripsList[storageAddTripsDataStr.categoryId] === undefined ||
                                tripsList[storageAddTripsDataStr.categoryId] === null ||
                                tripsList[storageAddTripsDataStr.categoryId].length === 0) {
                                let totalNum = storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum
                                for (let i = 0; i < storageAddTripsDataStr.params.length; i++) {
                                    totalNum += storageAddTripsDataStr.params[i].num
                                }
                                storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum = totalNum
                                storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId] = storageAddTripsDataStr.params
                            } else {
                                let totalNum = storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum
                                let categoryList = storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId]

                                for (let j = 0; j < categoryList.length; j++) {
                                    for (let k = 0; k < storageAddTripsDataStr.params.length; k++) {
                                        totalNum += storageAddTripsDataStr.params[k].num

                                        if (categoryList[j].skuId === storageAddTripsDataStr.params[k].skuId) {
                                            storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId][j].num += storageAddTripsDataStr.params[k].num
                                            storageAddTripsDataStr.params.splice(k, 1)
                                        }
                                    }
                                }

                                storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId] =
                                    [...storageTripsDataStr.tripPlannings[tripPlanningsIndex][storageAddTripsDataStr.categoryId], ...storageAddTripsDataStr.params]

                                storageTripsDataStr.tripPlannings[tripPlanningsIndex].todayTripsTotalNum = totalNum
                            }
                        }
                    }
                }

                updateTripData(storageTripsDataStr)
                sessionStorage.setItem(global.STORAGE_KEY_ADD_TRIPS_DATA, '')
            }
        } else {
            setTripsData(storageTripsDataStr)
        }
    }, [props.location.query])

    useEffect(() => {
        getCommoditiesTypes()
    }, []);

    useEffect(() => {
        let totalPrice = 0

        if (trips && trips.tripPlannings) {
            totalPrice = trips.tripPlannings.reduce(function (price, planningsItem) {
                let oneDayTotalPrice = commoditiesList.reduce(function (price, commoditiesItem) {
                    let planningsTotalPrice = 0
                    if (planningsItem[commoditiesItem.categoryId] && planningsItem[commoditiesItem.categoryId].length > 0) {
                        planningsTotalPrice = planningsItem[commoditiesItem.categoryId].reduce(function (price, item) {
                            return price + item.price * item.num
                        }, 0)
                    }
                    return price + planningsTotalPrice
                }, 0)
                return price + oneDayTotalPrice
            }, 0)
        }

        setTotalPrice(totalPrice.toFixed(2))
    }, [commoditiesList, trips]);

    /**
     * 获取商品类型
     */
    function getCommoditiesTypes() {
        let storageTripsData = sessionStorage.getItem(global.STORAGE_KEY_TRIPS_DATA);
        let storageTripsDataStr = storageTripsData ? JSON.parse(storageTripsData) : {};
        const startDate = moment(storageTripsDataStr.departDate).format("YYYY-MM-DD");
        const endDate = moment(storageTripsDataStr.returnDate).format("YYYY-MM-DD");

        setLoadingState(true)
        fetch(api.base + api.commoditiesTypes + "?startDate=" + startDate + "&endDate=" + endDate)
            .then(response => response.json())
            .then(res => {
                setLoadingState(false)
                if (res && res.code === 200) {
                    setCommoditiesList(res.data)
                }
            })
    }

    /**
     * 行程添加数量
     */
    function addTripNum(categoryId, index) {
        trips.tripPlannings[currentIndex][categoryId][index].num++
        trips.tripPlannings[currentIndex].todayTripsTotalNum++
        updateTripData(trips)
    }

    /**
     * 行程减少数量
     */
    function subtractTripNum(categoryId, index) {
        trips.tripPlannings[currentIndex][categoryId][index].num--
        trips.tripPlannings[currentIndex].todayTripsTotalNum--
        updateTripData(trips)
    }

    /**
     * 删除行程
     */
    function closeTrip(categoryId, index) {
        trips.tripPlannings[currentIndex].todayTripsTotalNum -= trips.tripPlannings[currentIndex][categoryId][index].num
        trips.tripPlannings[currentIndex][categoryId].splice(index, 1)
        updateTripData(trips)
    }

    /**
     * 更新行程
     */
    function updateTripData(data) {
        let jsonStr = JSON.stringify(data)
        setTripsData(JSON.parse(jsonStr))

        sessionStorage.setItem(global.STORAGE_KEY_TRIPS_DATA, jsonStr);
    }

    /**
     * 获取商品列表页路径
     */
    function getTrainTicketPath(type) {
        let date = trips && trips.tripPlannings && trips.tripPlannings.length > 0 ? trips.tripPlannings[currentIndex].date : ''
        return {pathname: '/select_train_ticket', state: {categoryId: type, date: date}}
    }

    /**
     * 下一步
     */
    function nextBtn() {
        if (commoditiesList === undefined || commoditiesList === null || commoditiesList === '') {
            Toast.info('网络失败，请稍后尝试')
            return
        }

        if (trips === undefined || trips === null || trips === '') {
            Toast.info('网络失败，请稍后尝试')
            return
        }

        if (trips.tripPlannings === undefined || trips.tripPlannings === null || trips.tripPlannings === '') {
            Toast.info('请规划您的行程')
            return
        }

        let isOk = false
        let message = ''
        for (let i = 0; i < commoditiesList.length; i++) {
            if (commoditiesList[i].singleBuy === 0) {
                if (message === '') {
                    message = commoditiesList[i].categoryName
                } else {
                    message += '/' + commoditiesList[i].categoryName
                }
            }

            if (commoditiesList[i].singleBuy === 1) {
                for (let tripPlanningsIndex = 0; tripPlanningsIndex < trips.tripPlannings.length; tripPlanningsIndex++) {
                    let data = trips.tripPlannings[tripPlanningsIndex][commoditiesList[i].categoryId]
                    if (data !== undefined && data !== null && data !== '' && data.length > 0) {
                        isOk = true
                        break
                    }
                }
            }
        }

        if (!isOk) {
            Toast.info('请规划您的行程,' + message + '不能单独购买')
            return
        }

        trips['companyID'] = commoditiesList[0].companyID
        trips['totalPrice'] = totalPrice

        sessionStorage.setItem(global.STORAGE_KEY_TRIPS_DATA, JSON.stringify(trips));
        sessionStorage.setItem(global.STORAGE_KEY_COMMODITIES_LIST, JSON.stringify(commoditiesList));

        props.history.push('/confirmation')
    }

    /**
     * 获取左侧日期布局
     */
    function getDateItem(data) {
        return data.map((item, index) => {
            return (
                <div className='ss-item-left-container'
                     key={index}
                     style={index === currentIndex ?
                         {backgroundColor: '#ffffff'} :
                         null}
                     onClick={() => {
                         setCurrentIndex(index)
                         sessionStorage.setItem(global.STORAGE_KEY_SELECT_DAY, JSON.stringify(item.date))
                     }}
                >
                    {
                        index === currentIndex ?
                            <div className='ss-item-left-blue-line'/>
                            : <div style={{width: 4, height: 20}}/>
                    }
                    <div className='ss-item-left-date'>
                          <span
                              style={index === currentIndex ?
                                  {color: '#333333', fontSize: 15, fontWeight: 'bold'} :
                                  {color: '#999999', fontSize: 15}}
                          >第{index + 1}天</span>
                        <span
                            style={index === currentIndex ?
                                {color: '#333333', fontSize: 10, marginTop: 5, fontWeight: 'bold'} :
                                {color: '#999999', fontSize: 10, marginTop: 5}}
                        >（{moment(item.date).format('MMMDo')}）</span>
                    </div>
                    {
                        item.todayTripsTotalNum !== 0 ?
                            <div className='ss-item-left-num'>
                                <span>{item.todayTripsTotalNum}</span>
                            </div> : <div style={{width: 13, height: 13, marginLeft: -12}}/>
                    }

                </div>
            )
        })
    }

    /**
     * 获取右侧顶部商品类型布局
     */
    function getCommoditiesItem(data) {
        return data.map((item, index) => {
            return (
                <Link
                    className="ss-right-top-btn"
                    key={index}
                    to={getTrainTicketPath(item.categoryId)}
                    onClick={() => {
                        let totalNum = 0
                        for (let i = 0; i < trips.tripPlannings.length; i++) {
                            totalNum += trips.tripPlannings[i].todayTripsTotalNum
                        }

                        trips['totalNum'] = totalNum
                        sessionStorage.setItem(global.STORAGE_KEY_TRIPS_DATA, JSON.stringify(trips));
                    }}>
                    <span>+{item.categoryName}</span>
                </Link>
            )
        })
    }

    /**
     * 获取右侧底部行程布局
     */
    function getTripsContainer() {
        let plannings = commoditiesList.filter((item, index) => {
            return trips.tripPlannings[currentIndex][item.categoryId] !== undefined &&
                trips.tripPlannings[currentIndex][item.categoryId] !== null &&
                trips.tripPlannings[currentIndex][item.categoryId].length > 0
        })

        if (plannings.length < 1) {
            return (
                <div className='ss-right-bottom-no-data'>
                    请选择今日行程规划
                </div>
            )
        }

        return plannings.map((commodities, index) => {
            return (
                <div className='ss-right-bottom-type-container' key={index}>
                    <span className='type-commodities-name'>{commodities.categoryName}</span>
                    {getTripItem(trips.tripPlannings[currentIndex], commodities.categoryId)}
                </div>
            )
        })
    }

    /**
     * 获取底部行程布局
     */
    function getTripItem(data, categoryId) {
        return data[categoryId].map((item, index) => {
            let label = item.names.reduce((name, current) => {
                return name += " " + current
            })

            // 77%（右侧总宽度） - 80（图片宽度）-30（叉的宽度）-30（外侧边框）
            let maxWidth = window.screen.availWidth * 0.77 - 80 - 30 - 30

            return (
                <div className='type-item-container' key={index}>

                    <div className='type-item-close'
                         onClick={() => {
                             alert('', '确认删除？', [
                                 {
                                     text: '取消', onPress: () => {
                                     }
                                 },
                                 {text: '确定', onPress: () => closeTrip(categoryId, index)}
                             ])
                         }}>
                        <Icon size='xs' type='cross' color='#999999'/>
                    </div>

                    <div className='type-item-right-container'>

                        <div className='type-item-image'>
                            <img src={item.imgSrc} alt=""/>
                        </div>

                        <div style={{width: maxWidth, maxWidth: maxWidth}} className='type-item-message'>

                            <span className='type-item-message-title'>{item.title}</span>

                            <div className='type-item-message-detail'>
                                <span>{label}</span>
                            </div>

                            <div className='type-item-message-number-container'>

                                <span style={{color: '#E03131'}}>￥{item.price}</span>

                                <div className='type-item-message-change-num'>
                                    <img
                                        src={subtractIcon}
                                        alt=""
                                        onClick={() => {
                                            if (item.num > 1) {
                                                subtractTripNum(categoryId, index)
                                            }
                                        }}
                                    />
                                    <span className="type-item-message-num">{item.num}</span>
                                    <img
                                        src={addIcon}
                                        alt=""
                                        onClick={() => {
                                            addTripNum(categoryId, index)
                                        }}
                                    />

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            )
        })
    }

    return (
        <div className="select-service-container">
            <ActivityIndicator toast text="正在加载" animating={loading}/>

            <div className='ss-data-container'>

                <div className="ss-date-container">
                    {trips && trips.tripPlannings && trips.tripPlannings.length > 0 ? getDateItem(trips.tripPlannings) : null}
                </div>

                <div className="ss-right-container">
                    {
                        commoditiesList && commoditiesList.length > 0 ?
                            <div className="ss-right-top-container">
                                {getCommoditiesItem(commoditiesList)}
                            </div> : null
                    }

                    {
                        trips && trips.tripPlannings && trips.tripPlannings.length > 0 ?
                            <div className='ss-right-bottom-container'>
                                {getTripsContainer()}
                            </div>
                            : null
                    }

                </div>

            </div>


            <div className='ss-next-btn-container'>

                <div className='ss-total-price-container'>
                    <span style={{color: 'black', fontSize: 14}}>合计：</span>
                    <span style={{color: 'red', fontSize: 14, marginTop: 4}}>￥</span>
                    <span style={{color: 'red', fontSize: 20}}>{totalPrice}</span>
                </div>

                <div className='ss-next-btn'
                     onClick={() => {
                         nextBtn()
                     }}>
                    <span style={{fontSize: 17}}>下一步</span>
                </div>

            </div>

        </div>
    )
}

export default SelectService
